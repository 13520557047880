@import 'theme.scss';

.reset-password-popup {
  .reset-password-body {
    padding: 20px;
  }
  .signup_form-control {
    border-color: $primary-color !important;
  }
  .text-danger {
    font-size: 14px;
    margin-top: 4px;
    color: $red !important;
  }
  .forgot-password-Buttons {
    text-align: right;
    display: flex;
    justify-content: center;
    gap: 8px;
    .Request-otp-button {
      background-color: $primary-color;
      color: $White;
      padding: 6px 10px;
      width: fit-content;
    }

    .Request-otp-button:disabled {
      opacity: 0.65;
    }
    .submit-Button {
      background-color: $primary-color;
      color: $White;
      padding: 6px 10px;
      width: 95px;
    }

    .submit-Button:disabled {
      opacity: 0.65;
    }
    .close-Button {
      background-color: $White;
      color: $primary-color;
      padding: 6px 0px;
      width: 95px;
      border: 1px solid $primary-color;
    }
  }

  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center !important;
    padding: 0.35rem !important;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto !important;
    pointer-events: auto;
    background-color: $default-white;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }

  .forgotPasswordOtpScreen {
    .text-label {
      font-size: 13px;
      margin-bottom: 0px;
      margin-top: 12px;
      color: gray;

      .mandate {
        margin: 3px;
        color: $red;
      }
    }

    .otp-text-box-design {
      display: grid;
      grid-template-columns: 5fr 2fr;
      gap: 6px;

      .otp-timer-button {
        background-color: $primary-color;
        align-items: center;

        .otp-count-timer {
          color: $default-white;
          text-decoration: underline $default-white;
          text-align: center;
        }

        .otp-resend-button {
          color: $default-white;
          cursor: pointer;
          text-align: center;
          margin: auto;
          font-size: 16px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .forgotscreenPopup .modal-lg,
  .modal-xl {
    max-width: 660px !important;
  }
}

@media (min-width: 576px) {
  .forgotscreenPopup .modal-dialog {
    max-width: 500px;
  }
}

@media (max-width: 767px) {
  .inlineRadioOptions {
    margin-top: 25px !important;
  }
}

.modal-footer {
  justify-content: center !important;
  padding: 0.35rem !important;
}

.text-danger-reset-modal {
  font-size: 16px;
  margin-left: 25px;
  color: #dc3545;
}

.text-danger-reset-modal.one {
  margin-left: 0;
  margin-top: 2px;
}
