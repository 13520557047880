@import 'theme.scss';

.coffee_news_feeds_forShare {
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  margin-top: 90px;

  .no-data-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

    .no_data_found_image {
      width: 390px;
      margin: auto;

      .no_data_image {
        width: 100%;
        padding: 10px;
      }
      .error-share-news {
        width: fit-content;
        text-align: center;
        margin: auto;
        border: 1px solid $primary-color;
        background: $primary-color;
        color: $default-white;
        padding: 6px 16px;
        font-size: 18px;
        border-radius: 24px;
        margin-bottom: 36px;
      }
    }
  }

  .feeds_container {
    .news_feeds_card {
      margin-bottom: 10px;
      user-select: none;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: clamp(1vh, 10vw, 2rem);
      margin-right: clamp(1vh, 10vw, 2rem);
      padding-bottom: 3px;

      .design {
        display: flex;
        flex-direction: row;
        padding: 15px;
        border-radius: 10px;
        margin: 8px;
        box-shadow: 0px 0px 5px gray;
        gap: 10px;
        height: 100%;

        .subSecForImage {
          min-width: 410px;
          background-color: #ebe8e8;
          box-shadow: 0px 0px 5px gray;
          border-radius: 5px;

          .forMobileDeviceIndetail {
            display: none;
            justify-content: space-between;
            font-size: 15px;
          }

          .account_circle_div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .account_circle_icon {
            color: $primary-color;
          }

          .news_feeds_div {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .news_feeds_img {
            width: 100%;
            height: 230px;
            object-fit: cover;
            border-radius: 13px;
            padding: 8px;
          }
          .news_feeds_img_logo {
            width: 96%;
            height: 245px;
            margin: 8px;
            border-radius: 2px;
          }

          .slidingImg {
            box-sizing: border-box;
            position: absolute;
            perspective: 1000px;
            position: relative;
            display: flex;
            margin: auto;
            justify-content: center;

            .container-carosal {
              position: absolute;
              float: right;
              width: 200px;
              transform-style: preserve-3d;
              animation: rotar 25s infinite linear;
              animation-play-state: running;
              padding-top: 65px;

              img {
                width: 100%;
                height: 100px;
                object-fit: cover;
                overflow: hidden;
                position: absolute;
                background-color: white;
                padding: 4px;
                border-radius: 6px;
              }
            }

            .container-carosal div:nth-child(1) {
              transform: rotateY(var(--x1)) translateZ(var(--spacing1)) rotate(0deg);
            }

            .container-carosal div:nth-child(2) {
              transform: rotateY(var(--x2)) translateZ(var(--spacing1));
            }

            .container-carosal div:nth-child(3) {
              transform: rotateY(var(--x3)) translateZ(var(--spacing1));
            }

            .container-carosal div:nth-child(4) {
              transform: rotateY(var(--x4)) translateZ(var(--spacing1));
            }
          }
        }

        .subSecForNews {
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 5px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;

          .newsHeading {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 22px;
            color: #50b3f6;
            cursor: pointer;
          }

          .newsHtml {
            a {
              word-wrap: break-word;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            span {
              font-size: 16px;
              margin-bottom: 2px !important;
            }

            br {
              display: none;
            }
          }

          .newsDetails {
            display: flex;
            justify-content: space-between;

            .details {
              display: flex;
              gap: 30px;
              margin: 3px;
              color: gray;

              .access_time_icon_div {
                display: flex;
                background-color: blue;
                align-items: cneter;
                margin-top: 10px;
              }
              .visibility_icon_div {
                position: '';
                display: flex;
                justify-content: space-between;
              }
            }
            .read_more_button_div {
              display: flex;
              justify-content: center;
              align-items: center;

              .read_more_btn {
                font-size: 13px;
                width: 100px;
                background-color: $primary-color;
                color: $default-white;
                padding: 4px 8px;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
              }
            }
            .fullstop_style {
              color: $default-white;
              height: 70px;
            }
          }
        }
        .muiLogo {
          color: $primary-color !important;
          margin-right: 3px;
          width: 20px;
        }
      }

      .fixedcoffeenewsfeed {
        width: 100%;
      }

      .mainCards {
        display: flex;

        .details {
          display: flex;
        }

        :root {
          --x1: 0deg;
          --x2: 24deg;
          --x3: 48deg;
          --x4: 72deg;
          --x5: 96deg;
          --x6: 120deg;
          --spacing1: 450px;
          --image-width: 90%;
          --image-height: 120px;
        }

        .maindiv {
          margin: 200px;
        }

        .cardfooterstyle {
          height: 30px;
        }

        .container-carosal1 figure img {
          width: 136px;
          height: 100px;
          overflow: hidden;
          position: absolute;
          background-color: white;
          padding: 4px;
        }

        .container-carosal1 img {
          image-rendering: auto;
          transition: all 300ms;
          width: 50%;
          height: 60%;
          border-radius: 5px;
          box-shadow: gray 0px 0px 10px 2px;
        }

        .carosal-stop {
          animation: rotar 0s infinite linear;
        }

        @keyframes rotar {
          from {
            transform: rotateY(0deg);
          }

          to {
            transform: rotateY(360deg);
          }
        }
      }

      .rotate-img {
        border: 3px solid #f4f7f8;
        padding: 10px;
        text-align: center;

        .img-fluid {
          vertical-align: middle;
          border-style: none;
          box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
          padding: 5px;
          border: 1px solid #dbdbdb;
          width: 100% !important;
          max-height: 200px !important;
        }
      }

      .video-frame {
        max-width: 400px;
        max-height: 230px;
        border-radius: 3px;
        padding: 10px;
        margin: auto;
        justify-content: center;
      }

      .tabcolor {
        background-color: #ebe8e8 !important;
        color: #61676cfc;
        font-size: 20px;
        font-weight: 600;
        border-radius: 3px 30px 0px 0px !important;
        border-bottom: solid 2px #50b3f6;
      }

      .tabcolor1 {
        background-color: #ebe8e8 !important;
        color: #61676cfc;
        font-size: 20px;
        font-weight: 600;
        border-radius: 30px 3px 0px 0px !important;
        border-bottom: solid 2px #50b3f6;
      }
    }
  }

  .social_btns {
    padding: 0rem 1rem;
    max-width: 470px;
    margin: auto;
    margin-bottom: 13px;

    .flex {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
    .coffee_web_link_div {
      background-color: $default-white !important;
      border: 2px solid $primary-color !important;
      margin: 5px 0px;
    }

    .coffee_news_link {
      font-size: 20px;
      color: $primary-color;
    }

    .coffee_news_link_language_icon {
      width: 35px;
      height: 35px;
      color: $primary-color;
    }

    .flex_div {
      flex-direction: column;
    }

    .social_medial_links_div {
      margin-top: 30px;
      font-weight: bold;
      font-size: 19px;
    }

    .app-btn {
      width: 100%;
      color: #fff;
      margin: 5px 5px;
      text-align: left;
      border-radius: 5px;
      text-decoration: none;
      font-family: 'Lucida Grande', sans-serif;
      font-size: 10px;
      background-color: $Black;
      border: 1px solid lightgrey;
      height: 55px;

      p {
        margin: 0.5rem !important;
        color: white;

        .big-txt {
          font-size: 15px;
          text-transform: capitalize;
        }
      }

      .social_imgs {
        width: 20%;
        text-align: center;
        font-size: 28px;
        margin-right: 7px;
        color: white;
      }
      .social_media_google_play_img {
        width: 10%;
      }
      .social_media_apple_img {
        width: 10%;
      }
    }
  }
}

.website_link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

//Media Queries
@media (max-width: 767.98px) {
  .coffee_news_feeds_forShare {
    .feeds_container {
      max-width: 100%;

      .news_feeds_card {
        margin: 5px;

        .design {
          display: flex;
          flex-direction: column;

          .subSecForImage {
            min-width: auto;
            background-color: #ebe8e8;
            width: auto !important;
            height: auto;

            .forMobileDeviceIndetail {
              display: grid !important;
              grid-template-columns: auto 90px;
            }

            .slidingImg {
              .container-carosal {
                padding-top: 50px;
              }
            }
          }

          .subSecForNews {
            .newsDetails {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              margin-top: 7px;

              .details {
                display: none;
                flex-direction: column;
                gap: 4px;
              }

              .read_more_button_div {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;
              }
            }
          }
        }
      }
    }
  }
}
