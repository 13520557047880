@import 'theme.scss';

.p-inline-message.p-inline-message-info {
  border: solid $primary-color;
  border-width: 0 0 0 5px;
  color: $primary-color;
  width: 98%;
  display: flex;
  justify-content: flex-start;
  margin: 10px 0px 10px 6px;
}

.payment-container {
  margin-top: 100px;
  margin-bottom: 30px;

  .payment-method {
    display: flex;
    justify-content: center;
    height: auto;
    gap: 10px;
    margin: auto;

    .payment-card {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: $default-white;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      .order-container {
        color: $default-white;
        border: 1px;
        .order-summary {
          margin: 15px 15px 15px 15px;
          padding: 0px;
        }
      }
      .payment-method-container {
        color: $default-white;
        border-radius: 1px;
      }
      .subscription-conatiner {
        display: grid;
        grid-template-columns: auto 20px 80px;
        padding: 0px 25px;
        .subscription-name {
          font-size: 20px;
          font-weight: 600;
        }
        .subscription-country {
          font-size: 20px;
          font-weight: 600;
        }
        .subscription-baseprice {
          font-size: 20px;
          font-weight: 600;
          justify-self: end;
          display: flex;
        }
        .discount-amount {
          font-size: 18px;
          font-weight: $fw-bold;
        }
        .discount-cureency {
          font-size: 18px;
          font-weight: $fw-bold;
          justify-self: end;
          display: flex;
        }
        .actual-amount {
          font-size: 18px;
          font-weight: 600;
        }
        .actual-currency {
          font-size: 18px;
          font-weight: $fw-bold;
          justify-self: end;
          display: flex;
        }
        .cgst {
          font-size: 18px;
          font-weight: $fw-bold;
        }
        .cgst-amount {
          font-size: 18px;
          font-weight: $fw-bold;
          justify-self: end;
          display: flex;
        }
        .cgst-currencytype {
          font-size: 18px;
          font-weight: $fw-bold;
        }
        .sgst {
          font-size: 18px;
          font-weight: $fw-bold;
        }
        .sgst-currency {
          font-size: 18px;
          font-weight: $fw-bold;
        }
        .sgst-amount {
          font-size: 18px;
          font-weight: $fw-bold;
          justify-self: end;
          display: flex;
        }
        .round-to-nearby {
          font-size: 18px;
          font-weight: $fw-bold;
        }
        .round-to-nearby-currency {
          font-size: 18px;
          font-weight: $fw-bold;
        }
        .round-to-nearby-details {
          font-size: 18px;
          font-weight: $fw-bold;
          justify-self: end;
          display: flex;
        }
        .igst {
          font-size: 18px;
          font-weight: $fw-bold;
        }
        .igst-currencytype {
          font-size: 18px;
          font-weight: $fw-bold;
        }
        .igst-amount {
          font-size: 18px;
          font-weight: $fw-bold;
          justify-self: end;
          display: flex;
        }
        .total {
          font-size: 20px;
          font-weight: 600;
        }
        .total-currency {
          font-size: 20px;
          font-weight: 600;
        }
        .cgst-tax {
          font-size: 20px;
          font-weight: 600;
          justify-self: end;
          display: flex;
        }
      }
      .billingInfo-card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: $default-white;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        width: 550px;
        margin: 10px 0px 0px -1px;
      }
    }
    .gst-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      color: $default-white;
      border-radius: 1px;
    }
    .payment-card {
      width: 100%;

      .payment-method-heading {
        margin: 15px;
        padding: 0px;
      }
      .no-payment {
        margin: auto;

        .no-payment-available {
          display: flex;
          justify-content: center;
        }
      }
      .billing {
        font-size: 23px;
      }

      .paypal-payment-btn {
        border-radius: 7px;
        width: 228px;
        height: 50px;
        margin: 12px auto !important;
        align-items: center !important;
        padding: auto;
        cursor: pointer;
        justify-content: center;
        display: flex;
        border: 1px solid $primary-color;
        overflow: hidden;
        box-shadow: 0px 0px 5px grey;

        img {
          width: 102%;
          height: 103%;
          object-fit: contain;
        }
      }
    }
  }

  .payment-method-gst {
    display: flex;
    justify-content: center;
    height: auto;
    gap: 10px;
    margin: auto;
  }
  .payment-card-gst {
    width: 100%;
  }
  .billingInfo-card-gst {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $default-white;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    width: 50%;
    margin: 10px 0px 0px -1px;
  }
  .gst-header-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    color: $default-white;
    border-radius: 1px;
  }
  .billing-text {
    font-size: 23px;
  }
  hr {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 767.98px) {
  .payment-container {
    margin-top: 60px;
    margin-bottom: 30px;

    .payment-method {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .payment-card {
        margin-top: 0px;
        .payment-method-heading {
          padding: 0px;
        }
        .billingInfo-card {
          width: 100%;
        }
      }
    }
    .payment-method-gst {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .payment-card-gst {
        margin-top: 0px;
        .billingInfo-card-gst {
          width: 100%;
        }
      }
    }
  }
}
.p-inline-message.p-inline-message-info {
  margin: 10px 0px 10px 4px;
}
.accordian-gst {
  padding: 0px;
  cursor: pointer;
}
.text-field {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
