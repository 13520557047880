@import 'theme.scss';
.blue-coffeequotes-bg {
  filter: blur(3px);
}

.coffee_quotes_screen {
  background-color: $default-white;
  width: 100%;
  z-index: 1 !important;
  top: 94px;
  top: 0px;
  margin-top: 90px;
  margin-bottom: 50px;

  .coffee_quotes_screen_header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: 3px 6px 3px 11px;
    position: fixed;
    width: 100%;
    background-color: $default-white;
    z-index: 2;

    .time_main_div_for_large_screen {
      display: flex;
      justify-content: space-between;
      height: 34px;
      gap: 3px;
      align-items: center;
      font-weight: 600;

      .time_sub_div {
        background-color: $default-white;
        border-radius: 4px;
        display: flex;
        padding: 4px 7px;
        align-items: center;
        border: 1px solid $primary-color;
      }

      .time {
        width: 120px;
      }
    }

    .time_main_div_for_mobile_screen {
      display: none;
      justify-content: space-between;
      height: 33px;
      align-items: center;
      gap: 6px;

      .time_sub_div {
        background-color: $default-white;
        border-radius: 4px;
        width: 50%;
        display: flex;
        padding: 4px 7px;
        height: 30px;
        align-items: center;
      }

      .time_colon {
        margin: 0px 2px;
      }
    }
    .trenging-news-exists {
      visibility: hidden;
    }

    .for_trending_news {
      height: auto;
      margin: 0px 17% 0% 20%;
      display: grid;
      grid-template-columns: auto 1fr;
      overflow: hidden;

      .labaling {
        align-items: center;
        color: #515151;
        display: flex;
        display: 'flex';
        font-weight: 600;
        justify-content: center;
        margin: 1.5px 5px;
        padding: 0 10px;
        width: 75px;
      }

      .marquee_main_div {
        padding-left: 100px;

        .marquee_tag {
          font-size: 16px;
          display: -webkit-inline-box;
          display: -moz-inline-box;
          display: -webkit-inline-flex;
          display: -ms-inline-flexbox;
          display: inline-box;
          display: inline-flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -o-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          -webkit-box-pack: center;
          -moz-box-pack: center;
          -o-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          -ms-transition: all 0.2s ease;
          transition: all 0.2s ease;
          cursor: pointer;
          white-space: nowrap;
          padding: 0px 14px 0px 3px;
          height: 30px;
          display: flex;
          vertical-align: middle;
          align-items: center;
          margin: 0px 60px;
          border-radius: 30px;
          color: black;
          background-color: $default-white;
          border-radius: 20px;

          .bullet_dot {
            font-weight: 500;
            background-color: $primary-color;
            border-radius: 50%;
            padding: 3px 3px;
            height: 8px;
            width: 8px;
            margin-right: 6px;
          }
        }
      }
      .marquee_news {
        display: flex;
        align-items: center;
      }
    }

    .search_and_add {
      margin: 0px 5px;
      display: grid;
      grid-template-columns: 1fr 4fr 1fr;
      justify-content: space-between;
      align-items: center;
      gap: 3px;
      padding-top: 1px;

      .select_dropdown_for_contract {
        height: 33px;
        font-size: 14px;
        background-color: $default-white !important;
        padding-left: 5px;
        cursor: pointer;
        border: 1px solid $primary-color !important;
      }

      label {
        margin-top: -10px;
      }

      .dividerDropDownDiv {
        width: 100%;
        border: 1px solid $primary-color;
        height: 32px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
      }

      .add_btn {
        width: 100%;
        height: 98%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .drop_down_for_add {
        width: 100%;
        .drop_down_div {
          width: 100%;
          .drop_down {
            width: 100%;
            display: flex;
            height: 33px;
            background-color: $default-white;
            border-radius: 3px;
            .auto_complete {
              max-height: 200px;
            }
          }
        }
      }
    }
  }

  .coffee_quotes_screen_body {
    margin-top: 40px;
    position: absolute;
    width: 100%;
    padding: 0px 10px 50px 10px;
    margin-bottom: 50px;

    .robusta_and_arabica_table {
      table-layout: auto;
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #dee2e6;
      margin-bottom: 0px;

      .table_head {
        background-color: $primary-color;
        color: $default-white;
      }

      td {
        font-size: 17px;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        vertical-align: middle;
        text-align: center;
        padding: 0rem 0.1rem;
      }
      .popup-icon {
        width: 15px;
        height: 15px;
        opacity: 0.5;
        margin-right: 3px;
      }
    }

    .worbook_table {
      width: 100%;
      border: 1px solid #dee2e6;
      overflow: hidden;

      .workbook_body {
        .dragIcon {
          cursor: move;
        }
        .delete-checkbox {
          display: flex;
          vertical-align: middle;
          margin: auto;
          cursor: pointer;
        }
        .custom-lastChg,
        .custom-netPrice {
          div {
            display: flex;
            justify-content: end;
            align-items: center;
            span {
              width: 70px;
            }
            input {
              width: 70px;
              margin-right: 0px;
            }
            input::placeholder {
              font-weight: 400;
            }
          }

          .swap-icon {
            width: 16px;
            height: 19px;
            color: #343a40;
            cursor: pointer;
            border: 1px solid $primary-color;
            margin-left: 3px;
            border-radius: 2px;
          }
        }
        .table_head {
          background-color: $primary-color;
          color: $default-white;
        }

        .newely_added_bg_color {
          background-color: #b0ef86;
        }

        .active_focus_bg_color {
          background-color: rgb(199, 198, 198) !important;
          transition: background-color 0.2s ease;
        }

        .normal_white_bg_color {
          background-color: $default-white;
          transition: background-color 0.6s ease;
        }

        td {
          font-size: 17px;
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          vertical-align: middle;
          text-align: center;
          padding: 0rem 0.1rem;
        }

        .table_head:hover {
          background-color: $primary-color;
        }

        tr:hover {
          background-color: rgb(224, 224, 224);
          transition: background-color 0s ease;
        }

        .input_field {
          height: 21px;
          width: 65px;
          font-size: 15px;
          align-items: center;
          display: block;
          margin: auto;
          padding: 0rem 0.2rem !important;
          font-weight: 400;
          line-height: 1.5;
          text-align: center;
          color: $dark_gray;
          background-color: $default-white;
          background-clip: padding-box;
          border: 1px solid $primary-color;
          border-radius: 0.25rem;
          transition:
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        }
        .input_field:focus-visible {
          border: 1px solid gray;
          outline: 2px solid $primary-color;
        }
        .custom-input {
          font-weight: 600;
        }
        .grade {
          width: 80px;
        }

        input::placeholder {
          color: rgb(182, 180, 180) !important;
          font-size: 13px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }

        .workbookDividerTd {
          background-color: $primary-color;
          height: 24px;
          font-size: 10px;

          .dividerInput {
            display: block;
            width: 25.9vw;
            text-align: center;
            color: black;
            font-weight: 600;
            border-radius: 4px;
            border: 1px solid $primary-color;
            font-size: 15px;
            text-align: center;
            left: 13.5vw;
            position: relative;
            outline: none;
            background-color: #fcfeea;
          }
        }

        .workBookIcons {
          width: 18px;
          cursor: pointer;
        }
      }
    }
    .span_net_price {
      width: 80px;
    }
    .fx_currency_title {
      width: 110px;
    }
    .contract_names {
      font-weight: 600;
      text-align: start !important;
      padding-left: 10px !important;
    }

    .highlight_color {
      background-color: #ccc;
    }

    .text_new_green {
      color: $green;
      font-weight: 600;
    }

    .text_new_red {
      color: $red;
      font-weight: 600;
    }

    .bold_and_red {
      color: $red;
      font-weight: 600;
    }

    .bold_and_black {
      color: $logo-text-color;
      font-weight: 600;
    }
    .bold_and_dark_gray {
      color: $dark_gray;
      font-weight: 600;
    }

    .expired {
      color: #c5c5c5;
    }

    .fx_currency {
      display: flex;
      justify-content: center;

      .fxcurrency_select_dropdown {
        font-size: 13px;
        width: 80px;
        text-align: center;
      }
    }
  }
}

.coffee_quotes_modal {
  margin-top: 120px;

  .coffee-quotes-modal-header {
    background-color: $primary-color;
    text-align: center;
    padding: 0px;
    .coffeeWebLogoModal {
      display: inline-block;
      align-items: flex-start;
      width: 180px;
      margin: auto;
      padding: 16px;
    }
    .coffeeWebDevLogo {
      display: inline-block;
      align-items: flex-start;
      width: 150px;
      margin: auto;
      padding: 0px;
    }
  }
  .coffee_quotes_modal_error {
    width: 100px;
    height: 100px;
    display: flex;
    margin: auto;
    color: #ffc107;
  }
  .coffee_quotes_modal_h4 {
    padding: 30px;
    text-align: center;
  }
  .coffee_quotes_modal_button_div {
    margin: auto;
    display: flex;
    justify-content: center;
    width: 40%;
    margin-top: -17px;
  }
  .coffee_quotes_modal_button {
    height: 35px;
    margin: 0;
    width: 90px;
  }
}

.coffee_quotes_comming_soon_modal {
  position: absolute;
  width: 100vw;
  top: 0px;
  z-index: 3;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  .comming_soon_card {
    padding: 100px;
    width: 450px;
    margin: auto;
    background-color: white;
    height: 300px;
    border-radius: 10px;
    font-size: 30px;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 0px 0px 4px gray;
  }
}

@media (min-width: 767.98px) and (max-width: 1360px) {
  .coffee_quotes_screen {
    .coffee_quotes_screen_body {
      td {
        font-size: 13px !important;
      }

      .worbook_table .workbook_body {
        .input_field {
          font-size: 13px !important;
        }
        .workbookDividerTd {
          .dividerInput {
            width: 27vw;
            left: 12.9vw;
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .coffee_quotes_screen {
    margin-top: 50px;

    .coffee_quotes_screen_header {
      display: flex;
      flex-direction: column;
      padding: 5px;
      gap: 5px;
      background-color: #e9ecef;

      .time_main_div_for_large_screen {
        width: 100%;
        display: none !important;
      }

      .time_main_div_for_mobile_screen {
        display: flex !important;
        width: 100%;

        .time_sub_div {
          background-color: $default-white;
          border-radius: 4px;
          display: flex;
          padding: 4px 5px;
          border: 1px solid $primary-color;
          justify-content: center;
          height: 34px;
          font-size: 16px;
        }
      }

      .for_trending_news {
        height: 34px !important;
        margin: 1px 0px;
        background-color: $default-white;
        border-radius: 4px;
        border: 1px solid $primary-color;

        .labaling {
          margin: 0px 5px 0px 0px !important;
        }
        .marquee_main_div {
          padding-left: 200px;
        }
      }

      .search_and_add {
        margin: 0px 0px;
        height: 32px;
      }
    }

    .coffee_quotes_screen_body {
      margin: 125px 0px 0px 0px;
      padding: 0px 0px 25px 0px;

      .robusta_and_arabica_table {
        display: none;
      }

      .worbook_table {
        display: none;
      }
    }
  }
  .coffee_quotes_comming_soon_modal {
    padding: 100px 10px 10px 10px;
    .comming_soon_card {
      width: 100%;
    }
  }
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  -webkit-transform: translate(10px, -8px) scale(0.8);

  transform: translate(14px, 6px) scale(1);
}

.MuiAutocomplete-hasPopupIcon.css-1ukkdn3-MuiAutocomplete-root {
  padding: 0px;

  height: 31px;
}

.MuiAutocomplete-hasPopupIcon.css-1ukkdn3-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  padding: 0px;

  height: 31px;
}

.css-1ukkdn3-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px;

  height: 31px;
}

.css-qowmc5-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 4px;
}

.css-qowmc5-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0px 4px 0px 6px;
}

.css-1yqgfch-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 3.5px 4px 3.5px 6px;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1yqgfch-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1yqgfch-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  padding: 0px;
}

.MuiAutocomplete-hasPopupIcon.css-1yqgfch-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1yqgfch-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 39px;

  margin-top: -0.8px;
}

.css-1yqgfch-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid $primary-color !important;
}

.css-igs3ac {
  border: 1px solid $primary-color !important;
}
