@import 'theme.scss';
.subscription-main-card {
  margin-top: 1px;
  padding: 7px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  .subscription-card {
    border-radius: 2rem;
    background-color: $default-white;
    border: 2px solid var(--subscription-color);
    box-shadow: 0px 0px 7px var(--subscription-color);
    .card-box {
      padding: 4px;
      position: absolute;
      top: 4px;
      left: 3px;
      span {
        align-items: center;
        display: flex;
        height: 135px;
        justify-content: center;
        overflow: hidden;
        position: absolute;
        width: 135px;
      }
    }

    .card-box span::before {
      align-items: center;
      background-color: #0094ff;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
      color: $default-white;
      content: 'Subscribed';
      display: flex;
      font-weight: 600;
      height: 40px;
      justify-content: center;
      letter-spacing: 0.1em;
      position: absolute;
      text-transform: uppercase;
      -webkit-transform: rotate(-45deg) translateY(-20px);
      transform: rotate(-45deg) translateY(-20px);
      width: 150%;
    }

    .card-box span::after {
      content: '';
      position: absolute;
      width: 10px;
      bottom: 0;
      left: 0;
      height: 10px;
      z-index: -1;
    }
    .Subscription-card-header {
      margin: 12px;
      display: flex;
      justify-content: center;
      padding: 5px;
      color: $default-white;
      border-radius: 21px;
      height: 100px;
      background-size: 400% 400%;
      animation: gradient 7s ease infinite;
      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }

      .validity-day {
        padding-left: 10px;
        .per-month {
          display: flex;
          justify-content: center;
        }
        .validity-Detail {
          display: flex;
        }
      }

      .div {
        width: 100%;

        .h3 {
          font-weight: 600;
        }

        .div {
          font-weight: 600;
          .h5 {
            font-weight: 600;
          }
          .h5 {
            font-weight: 600;
          }
        }
      }
      .subscription-basePrice {
        display: flex;
        justify-content: center;
        font-weight: 600;
        h5 {
          font-weight: 600;
        }
      }
      .subscription-currency {
        display: flex;
        justify-content: center;
        font-weight: 600;
      }
    }

    .Subscription-card-header {
      h3 {
        font-weight: 600;
      }
      .d-flex {
        font-weight: 600;
      }
    }
    .subscription-name {
      display: flex;
      justify-content: center;
    }

    .upgrade-button {
      background: $default-white;
    }

    .buy-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
      background: $bg_MainColor;
      border: 1px solid $default-white;
      padding: 6px 10px;

      &.renew {
        border: 1px solid $default-white;
      }
    }
    .renew-button {
      background: #50b3f6;
      border: 1px solid $default-white;
      padding: 6px 10px;
    }

    .feature-item {
      color: $default-white;
      padding: 2px 0px;
      .subscription-line {
        width: 100%;
        display: grid;
        grid-template-columns: 10% auto;
        font-size: 15px;
        padding: 1%;
        border-radius: 16px;
        gap: 4px;
        background-size: 400% 400%;
        animation: gradient 7s ease infinite;
        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        .feature-escription {
          display: flex;
          align-items: center;
        }
        .subscription-icon {
          background-color: $default-white;
          border-radius: 60%;
          width: 25px;
          height: 25px;
          padding: 1px;

          &.check {
            color: $green;
            padding: 0.25rem;
          }

          &.cancel {
            color: $red;
            padding: 0.25rem;
          }
        }
      }
    }
    .you-can-upgrade {
      background: $default-white;
      border: 1px solid rgb(80, 179, 246);
      color: rgb(80, 179, 246);
    }

    button {
      padding: 6px 10px;
      border: 0;
      border-radius: 100px;
      background: $bg_MainColor;
      color: $default-white;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      width: 155px;
    }

    button:hover {
      background-color: #6fc5ff;
      box-shadow: 0 0 20px #6fc5ff50;
    }
    .you-can-upgrade:hover {
      background-color: transparent;
      box-shadow: 0 0 20px #6fc5ff50;
    }

    button:active {
      background-color: #3d94cf;
      transition: all 0.25s;
      -webkit-transition: all 0.25s;
      box-shadow: none;
    }
  }

  .subscription-features {
    overflow-y: scroll;
    position: relative;
    border: 2px solid var(--subscription-color);
    box-shadow: 0px 0px 7px var(--subscription-color);
    padding: 10px;
    max-height: calc(100vh - 370px);

    .features-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .features-container {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
    .subscription-features-subscription-card-start {
      .div {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }

      .feature-Description {
        display: flex;
        align-items: center;
      }
    }
  }
  .subscription-features::-webkit-scrollbar {
    width: 2px;
  }

  .subscription-features::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $default-white;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .subscription-features::-webkit-scrollbar-thumb {
    background: rgb(179, 176, 176);
    border-radius: 10px;
  }
  .features-list > div {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  .upgrade-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1px;
    margin-bottom: 8px;
  }
  .upgrade-main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 1px;
  }
  .renew-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  }
}
.subscription-main-card#highlightedSubscriptionCard {
  background-color: $primary-color;
  border-radius: 37px;
  animation: blink 2s linear infinite;
  @keyframes blink {
    0% {
      background-color: $White;
    }
    30% {
      background-color: $primary-color;
    }
    100% {
      background-color: $White;
    }
  }
}
@media (max-width: 767.98px) {
  .subscription-main-card {
    margin-bottom: 22px;
    .feature-itemdiv {
      padding: 0px 0px;
      margin-top: 75px !important;
      .designSubscription-card {
        height: 117px !important;

        .validity-day {
          .validity-Detail {
            display: flex;
            flex-direction: column;
            font-size: 14px;
          }
        }
      }
    }

    .feature-itemdivmobile {
      display: block !important;
      padding: 12px;
      margin-bottom: 50px;

      .subscription-card {
        padding: 10px !important;
        border-radius: 20px !important;
        margin-top: 20px !important;
      }
    }
    .upgrade-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 4px;
      padding-bottom: 1px;
      margin-bottom: 10px;
    }
    .renew-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 4px;
      margin-bottom: 10px;
    }
  }
}
