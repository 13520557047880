.footer {
  background: #f2f1f1 !important;
  position: fixed;
  bottom: -1px;
  width: 100%;
  font-size: 14px;
  z-index: 199;
  color: gray;
  .terms_and_condition {
    display: inline-flex;
    gap: 3px;
    justify-content: center;
    margin-left: 4px;
  }
  .container {
    padding: 5px;
    min-width: 100%;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
  .version-number {
    position: absolute;
    right: 1px;
    bottom: 0px;
    font-size: 9px;
  }
}

.footer.slide-down {
  animation: slide-down-animation 0.2s ease-out;
  animation-fill-mode: forwards;
}

.footer.down {
  animation: down-animation 0.2s ease-out;
  animation-fill-mode: forwards;
}

@keyframes slide-down-animation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes down-animation {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
@media (max-width: 767px) {
  .footer {
    font-size: 9px;
    .terms_and_condition {
      display: flex;
      gap: 3px;
      width: 100%;
      justify-content: center;
      margin-left: 0px;
    }
    .version-number {
      display: none;
    }
  }
}
