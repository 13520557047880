body {
  font-family: sans-serif;
}

element.style {
  position: relative;
  top: 0px !important;
}

.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #b3b3b3;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
  font-family: 'Roboto', 'Arial', sans-serif;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: #dee2e6 !important;
  border-style: solid;
  border-width: 0;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
  vertical-align: middle !important;
}

.table thead th {
  vertical-align: middle;
  border-bottom: 2px solid #dee2e6;
  background-color: #50b3f6;
  color: white;
}

.react-bootstrap-table-pagination {
  text-align: left !important;
  margin-top: 0rem !important;
}

.eye-filled-off-icon {
  height: 20px;
  width: 20px;
}

@media (max-width: 767.98px) {
  .react-bootstrap-table {
    overflow-x: scroll !important;
  }

  .table {
    width: max-content !important;
  }

  .react-bootstrap-table-pagination {
    text-align: left !important;
    margin-top: 3rem;
  }

  .react-bootstrap-table-pagination {
    text-align: left !important;
    margin-top: 0rem !important;
  }
}

.navbar-brand {
  margin-top: 9px;
}

.skiptranslate {
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none;
}

.form-control {
  border: 1px solid #50b3f6 !important;
}

.input-group-text {
  border: 1px solid #50b3f6 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

select {
  border: 1px solid #50b3f6 !important;
  color: #545454 !important;
  font-weight: 400 !important;
  border-radius: 0.25rem;

  &:focus {
    border: 1px solid #6a94ed !important;
    color: #545454 !important;
    font-weight: 400 !important;
  }
}
