.subscription-main-container {
  height: calc(100vh - 180px);
  margin-bottom: 60px;
  margin-top: 115px;
  padding: 0;
  -webkit-user-select: none;
  user-select: none;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    display: none;
  }

  .swiper-pagination {
    margin-top: 10px;
    position: relative;
  }
}

@media screen and (max-width: 760px) {
  .subscription-main-container {
    margin-top: 100px;
    padding: 0;
  }
}
