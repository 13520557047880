@import 'theme.scss';

.global-differential-modal {
  max-width: 100vw;
  margin: 1.75rem auto;
  width: 100vw;
  pointer-events: none;
  user-select: none;
  .modal-dialog {
    width: 80vw;
    max-width: 100vw;
    min-width: 80vw;

    .modal-body {
      padding: 0.5rem;
    }
  }

  .global-differential-modal-header {
    padding: 0.2rem 1rem;

    .global-differential-modal-title {
      color: #50b3f6;
    }

    .global-differential-modal_close_button {
      float: end;
      cursor: pointer;
      margin-top: 5px;
    }
  }

  .left-arrow,
  .right-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    cursor: pointer;

    .left_icons,
    .right_icons {
      cursor: pointer;
      background-color: $White;
      border-radius: 50%;
      padding: 5px;
      width: 40px;
      height: 40px;
      border: 1px solid lightgray;
    }

    .left_icons {
      rotate: 180deg;
    }
  }

  .global-differential-header {
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 0px 60px;
    font-size: 20px;
    font-weight: $fw-bolder;
  }

  .global-differential-body {
    display: grid;
    grid-template-columns: auto 1fr auto;
    .slide-wrapper {
      min-width: 100%;
      max-width: 600px;
      margin: auto;
      margin-top: 0px;
      margin-bottom: 10px;
      .slide-title {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        color: $primary-color;
      }
      .slide-content {
        .history-data-slide {
          height: 65vh;
          width: 100%;
          overflow-x: auto;
          margin: auto;
          margin-top: 7px;
          .history-table-table-wparrer {
            height: 100%;
            overflow-x: auto;
            border-bottom: 1px solid #dee2e6;
          }
          table {
            border-collapse: collapse;
            text-align: center;
            width: 100%;
            border-bottom: 1px solid #dee2e6;
            font-weight: 600;
            thead {
              background-color: #50b3f6;
              color: $default-white;
              position: relative;
              tr {
                th {
                  border: 1px solid white;
                  background-color: #50b3f6;
                  padding: 4px;
                  img {
                    width: 30px;
                    border-radius: 2px;
                    border: 1px solid rgba(0, 17, 51, 0.15);
                    margin-top: -2px;
                  }
                }
              }
              .empty_div {
                position: absolute;
                background-color: $White;
                height: 100%;
                width: 100%;
                top: 0px;
                z-index: -1;
              }
            }
            tbody {
              tr {
                td {
                  border: 1px solid #dee2e6 !important;
                }
                &:hover {
                  background-color: rgba(0, 0, 0, 0.075);
                }
              }
              tr:nth-child(1) {
                background-color: rgb(233, 241, 249);
              }
              .remark-cell {
                max-width: 300px;
              }
            }
          }
          .mobile-view {
            .popup-header {
              font-weight: 600;
              margin: 0px 4px;
              img {
                width: 30px;
                border-radius: 2px;
                border: 1px solid rgba(0, 17, 51, 0.15);
              }
            }
            .history-data-cards {
              .each-card {
                border: 1px solid lightgray;
                border-radius: 4px;
                margin: 10px 4px;
                padding: 5px 10px;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                gap: 3px;
                .each-row {
                  display: flex;
                  align-items: flex-start;
                  span {
                    color: gray;
                    margin-right: 3px;
                  }
                  .differentil-between {
                    display: flex;
                    gap: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .empty-block {
      width: 45px;
    }
  }
}

@media (max-width: 767.98px) {
  .global-differential-modal {
    .global-differential-header {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0px;
      font-size: 14px;
      font-weight: $fw-bolder;
    }

    .global-differential-body {
      grid-template-columns: 0px 1fr 0px;
      .left-arrow {
        width: auto;
      }
      .left-arrow {
        width: auto;
        margin-left: -23px;
      }
      .right-arrow {
        width: auto;
        margin-left: 10px;
      }
      .slide-wrapper {
        .slide-content {
          .history-data-slide {
            width: 100%;
            height: 75vh;
            overflow-x: auto;
            margin: auto;
            margin-top: 7px;
            table {
              tr {
                font-size: 13px;
              }
            }
          }
        }
      }
    }

    .modal-dialog {
      margin: auto;
      width: 90vw;
    }
  }
}
