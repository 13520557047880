@import 'theme.scss';

:root {
  --loginThrougnOtpTab: 100%;
}

.login_screen {
  .nav-link {
    color: #495057;
  }
  .login_card {
    width: 100%;
    margin: auto;
    max-width: 460px;
    min-height: auto;
    position: relative;
    left: 10%;
    box-shadow:
      0 12px 15px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);

    .frmlogn {
      .errorMessage {
        color: $red !important;
        font-size: 14px;
      }
    }

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }

      50% {
        background-position: 100% 50%;
      }

      100% {
        background-position: 0% 50%;
      }
    }

    .email_input {
      margin-bottom: 1.5rem;

      .form-group {
        margin-bottom: 0 !important;
      }
    }

    .login_form-control {
      outline: none;
      border: none;
      border: 1px solid $bg_MainColor;
      background: rgba(255, 255, 255, 0.2);
      padding-left: 14px;
      border-radius: unset;
      color: #000;
      font-size: 16px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
      border-radius: 0px 4px 4px 0px;
    }

    input::placeholder {
      background-color: $default-white;
    }

    .login_form-control:focus {
      color: unset !important;
      background: rgba(255, 255, 255, 0.2);
      border-color: unset !important;
      border: 1px solid $bg_MainColor !important;
      outline: 0;
      box-shadow: unset !important;
    }

    .login_card_body {
      padding: 1.25rem;
      margin: 30px 0px 0px 0px;
      p {
        color: $red;
      }
      .invalid_credentials {
        position: absolute;
        top: 60px;
      }
      .forgot_pass {
        margin: 18px 0px 22px 0px;
        .forgot_pass_text {
          color: rgb(0, 123, 255);
          text-align: center;
          width: fit-content;
          margin: auto;
          cursor: pointer;
        }
      }
    }

    .nav-tabs {
      padding: 0.2rem;
      background-color: #f1f1f1;

      .nav-item {
        padding-bottom: unset !important;
        width: 50%;
      }

      .nav-link {
        margin-bottom: -1px;
        border: 1px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        font-weight: 700;
        font-size: 15px;
        width: 100%;
        transition: 0s !important;

        &:hover {
          color: $bg_MainColor;
        }
      }

      .nav-link.active {
        color: #495057;
        background-color: $default-white;
        border-color: #dee2e6 #dee2e6 #fff;
        width: 100%;
        color: $default-white;
        font-size: 15px;
        font-weight: 600;
        background-color: $primary-color !important;
        border-bottom: 0px !important;
        border-radius: 3px !important;
        transition: 0s !important;
      }
    }

    .pass-wrapper {
      position: relative;
      display: flex;
      width: fit-content;
      width: 100%;

      .eye_icon {
        position: absolute;
        top: 16%;
        border: unset;
        background: unset;
        width: auto;
        right: 9px;

        img {
          width: 20px;
          cursor: pointer;
        }
      }
    }

    .coffe-hard {
      background-color: $bg_MainColor;
    }

    .btn.focus,
    .btn:focus {
      outline: 0;
      box-shadow: unset;
      border: unset !important;
    }

    .login_btn {
      width: 100%;
      color: $default-white;
      margin: 10px 0px;

      .login-link {
        color: $default-white;
        text-decoration: none;
      }
    }
  }

  .flex {
    width: 100%;
    display: flex;
  }

  .password_block {
    .coffe-hard {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .login_links {
    background-color: #d3d3d394;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 15px;
    margin: 20px 0px;
    color: #494c50;

    .sign_up_btn {
      margin-left: 10px;
      color: $default-white;
      padding: 2px 10px;
    }
  }

  .loginSupportButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
}

.login-confirmation-modal {
  background-color: $primary-color;
  padding: 0px !important;

  .coffeeWebLogoModal {
    display: inline-block;
    align-items: flex-start;
    width: 180px;
    margin: auto;
    padding: 16px;
  }
  .coffeeWebDevLogo {
    display: inline-block;
    align-items: flex-start;
    width: 150px;
    margin: auto;
    padding: 0px;
  }
}

@media screen and (max-width: 1199px) {
  .login_screen {
    .login_card {
      left: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .login_screen {
    .login_card {
      .nav-tabs {
        .nav-item {
          width: 50%;

          .nav-link {
            width: 100%;
          }
        }
      }

      .pass-wrapper {
        width: 100%;

        .pass_flow {
          width: 100%;
        }
      }
    }

    .input-group {
      flex-wrap: initial !important;
    }

    .password_block {
      .pass-wrapper {
        width: 100%;
      }
    }
  }

  .pass_flow {
    width: 100%;
  }

  .mobile_block {
    display: block !important;
  }

  .pre_header {
    .collapse:not(.show) {
      display: block !important;
    }

    .navbar-collapse {
      flex-basis: initial;
      flex-grow: initial;
    }
  }
}

@media (max-width: 767px) {
  .pre_header {
    .navbar {
      .main_logo {
        text-align: center;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 50%;

        svg {
          width: 200px;
        }
      }
    }
  }

  .login_screen {
    .login_card {
      width: 97%;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      min-height: auto;
      position: relative;
      left: unset;

      .nav-tabs {
        .nav-link.active {
          color: #495057;
          background-color: $default-white;
          border-color: #dee2e6 #dee2e6 #fff;
          color: $bg_MainColor;
          padding: 0.5rem;
          color: $default-white;
        }
      }

      .pass-wrapper {
        width: 100%;

        .pass_flow {
          width: 100%;
        }
      }
    }
  }

  .collapse:not(.show) {
    position: absolute;
    right: 0;
    width: 50%;
  }
}

@media (max-width: 350px) {
  .goog-te-combo {
    font-size: 14px !important;
    width: 135px !important;
  }

  .navbar-nav {
    flex-direction: inherit !important;
  }

  .login_screen {
    .login_card {
      .nav-tabs {
        .nav-link {
          font-size: 9px;
        }
      }
    }
  }
}

:root {
  --margin1: 45px;
  --padding1: 0px;
}
