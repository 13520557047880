@import 'theme.scss';

.otp-field {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 18px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 8px;
    }

    input {
      width: 70%;
      padding: 8px 10px;
      font-size: 20px;
      text-align: center;
      border: 1px solid $primary-color;
      border-radius: 5px;
    }

    input:focus {
      border: 3px solid #aedeff;
      outline: none;
    }
  }
}

.error-message {
  font-size: 16px;
  color: $red !important;
  margin: 0px;
}

.timer-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.otp-mismatch-error {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signUpTabScreen {
  padding: 0 1.23rem;

  .signup_form-control {
    border-color: $primary-color !important;
  }
  .frmsignup {
    .error-country-dropdown {
      margin: 0px 13px;
      font-size: 16px;
      color: $red;
    }

    .countryDropDownForSignUp {
      .react-custom-flag-select__select__options-container {
        width: 100%;
      }

      .react-custom-flag-select__select__container {
        width: 100%;

        ::-webkit-scrollbar {
          width: 6px !important;
        }
      }

      .react-custom-flag-select__select__buttonWrapper {
        height: 100%;
        border-radius: 4px;
        border: 1px solid $primary-color !important;
      }

      .react-custom-flag-select__select__searchInput {
        border: 1px solid $primary-color;
        outline-color: $primary-color;
        color: #000;
      }

      .react-custom-flag-select__select__selector {
        padding: 0 7px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: start;
        width: 100%;
      }

      .react-custom-flag-select__select__hover-active {
        background-color: $primary-color;
        color: $default-white;
      }

      .react-custom-flag-select__select__options-item div {
        text-align: center;
      }

      .react-custom-flag-select__select__options-item div {
        text-align: left !important;
      }

      .react-custom-flag-select__select__dropdown-flag {
        width: 32px;
        flex: none !important;
      }

      .react-custom-flag-select__select__dropdown-flag img {
        text-align: left;
        width: 30px !important;
        border-radius: 2px;
        border: 1px solid rgba(0, 17, 51, 0.15);
      }

      .react-custom-flag-select__select__dropdown-flag div {
        text-align: left !important;
      }

      .react-custom-flag-select__select__dropdown-name {
        justify-content: start !important;
        text-align: left;
      }

      .react-custom-flag-select__select__dropdown-name div {
        text-align: left;
        width: 100%;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .react-custom-flag-select__select__options-item-show-cursor {
        display: grid !important;
        grid-template-columns: 32px 1fr;
        column-gap: 11px;
      }
    }

    .form-label {
      font-size: 13px;
      color: grey;
      margin-top: 9px;
      margin-bottom: 0px;

      .mandate {
        color: $red;
        margin: 0px 3px;
      }
    }

    .signup_phone_field {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .text-danger {
      margin-bottom: 0px !important;
      font-size: 14px;
      color: $red !important;
    }

    .termsAndPolicyFooterStyle {
      font-size: 12px;
      margin: 7px 0px 7px 0px;
      color: #495057eb;
      text-align: center;
    }

    .continueButton {
      text-align: center;

      .continueButtonStyle {
        color: white;
        background-color: $primary-color;
        padding: 0.375rem 0.75rem;
        width: 100%;
        height: 37px;
      }
      .continueButtonStyle.disabled,
      .continueButtonStyle:disabled {
        opacity: 0.65;
      }
    }

    .redirect_to_login_container {
      background-color: #d3d3d3d4;
      padding: 6px 10px;
      border-radius: 5px;
      margin-top: 15px;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: #494c50;
      }

      button {
        margin-left: 10;
        color: $primary-color;
        background-color: #e9ecef;
        border: 1px solid $primary-color;
        padding: 2px 10px;
      }
    }
  }
}
