.coffeequotes_accordian_component {
  cursor: pointer;
}

.terminalDetailsModalPopup {
  max-width: 100vw !important;
  margin: 1.75rem auto;
  width: 100vw !important;
  pointer-events: none;
  user-select: none;

  .terminal_details_modal_header {
    padding: 0.2rem 1rem;

    .terminal_details_modal_title {
      color: #50b3f6;
    }

    .terminal_details_modal_close_button {
      float: end;
      cursor: pointer;
      margin-top: 5px;
    }
  }

  .terminal_detail_table {
    thead tr {
      background-color: #50b3f6;
      color: white;
      text-align: center;
    }

    tbody tr {
      background-color: white;
    }

    tbody td {
      text-align: center;
    }

    .table_td {
      font-weight: 600;
    }

    table {
      width: 100%;
      border: 1px solid #50b3f6;
    }

    .text_new_green {
      color: rgb(0, 153, 0);
      font-weight: 600;
    }

    .text_new_red {
      color: red;
      font-weight: 600;
    }
  }

  .terminal_detail_body {
    display: grid;
    height: 69vh;
    grid-template-columns: 60px auto 60px;

    .slide_1 {
      margin-top: 15px;
      display: grid;
      grid-template-columns: 4fr 5fr;
      gap: 15px;

      .difference_table {
        border-collapse: collapse;
        border: 1px solid #50b3f6 !important;

        td,
        th {
          text-align: center;
        }

        .positive_green {
          color: green;
          font-weight: 600;
          border: 1px solid #50b3f6 !important;
        }

        .negative_red {
          color: red;
          font-weight: 600;
          border: 1px solid #50b3f6 !important;
        }

        .idle_color {
          border: 1px solid #50b3f6 !important;
        }

        .difference_table_head {
          color: white;
          text-align: center;
          background-color: #50b3f6;
          margin-bottom: 8px;
          font-weight: 600;
          .last_column {
            border-right: 1px solid white !important;
            border-left: 1px solid white !important;
            width: 30%;
          }
        }

        .table_tr {
          height: 3px;
        }

        .difference_table_body {
          .differential_column {
            font-size: 18px;
            border: 1px solid #50b3f6 !important;
          }

          .difference_table_tr {
            margin-bottom: 5px;
          }

          .difference_table_td {
            font-weight: 400;
          }

          .difference_table_tr_height {
            height: 6px;
          }
        }
      }

      .contract_high_low {
        width: 100%;
        border: 1px solid #50b3f6;

        .contract_header {
          background-color: #50b3f6;
          color: white;
          text-align: center;
          margin-bottom: 8px;
          font-weight: 600;

          .contract_header_td {
            border-right: 1px solid white;
          }
        }
        .contract_header_tr {
          height: 3px;
        }

        .contract_body {
          text-align: center;
          border: 1px solid #50b3f6;
          background-color: white;

          td {
            border: 1px solid #50b3f6 !important;
            width: 25%;
          }
        }
      }
    }

    .slide_2 {
      gap: 15px;

      .no-termianl-data {
        height: 54vh;
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .terminal_heading {
        display: flex;
        justify-content: center;
        font-size: 19px;
        margin: 5px 0px;
      }

      .terminal_table {
        border: 1px solid #50b3f6;
        border-top: 0px;

        .positive_green {
          color: green;
        }

        .negative_red {
          color: red;
        }

        .terminal_table_head {
          display: grid;
          grid-template-columns: 5fr 3fr 3fr 3fr 3fr 3fr 3fr 6fr 3fr 6fr 17px;
          background-color: #50b3f6;
          color: white;

          div {
            padding: 3px 0px;
            display: flex;
            justify-content: center;
            border-right: 1px solid white;
            border-top: 1px solid white;
            font-weight: 600;
          }
        }

        .today_terminal_table_head {
          display: grid;
          grid-template-columns: 5fr 3fr 3fr 3fr 3fr 3fr 3fr 6fr 3fr 6fr 17px;
          background-color: white;
          margin-top: 4px;
          font-weight: 600;

          .today_termianal_div {
            font-weight: 600;
          }

          div {
            padding: 3px 0px;
            display: flex;
            justify-content: center;
            border: 1px solid #50b3f6;
          }
        }

        .no-termianl-data-table {
          height: 0vh !important;
        }
        .terminal_table_main_body {
          height: 54vh;
          overflow: scroll;
          overflow-x: hidden !important;

          .terminal_table_body {
            display: grid;
            grid-template-columns: 5fr 3fr 3fr 3fr 3fr 3fr 3fr 6fr 3fr 6fr;

            div {
              border: 1px solid #50b3f6;
              display: flex;
              justify-content: center;
              padding: 3px 0px;
            }
          }

          .positive_green {
            color: green;
          }

          .negative_red {
            color: red;
          }
        }

        .terminal_table_main_body::-webkit-scrollbar {
          width: auto;
        }
      }
    }

    .arrows {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      cursor: pointer;

      .left_icons {
        animation: left_icons_keyframe 1.5s infinite;
        cursor: pointer;
        rotate: 180deg;
      }

      .right_icons {
        animation: right_icons_keyframe 1.5s infinite;
        cursor: pointer;
      }

      @keyframes left_icons_keyframe {
        0% {
          margin-left: -20px;
        }

        50% {
          margin-left: 0px;
        }

        100% {
          margin-left: -20px;
        }
      }

      @keyframes right_icons_keyframe {
        0% {
          margin-left: 20px;
        }

        50% {
          margin-left: 0px;
        }

        100% {
          margin-left: 20px;
        }
      }
    }
  }

  .modal-dialog {
    width: 80vw !important;
    max-width: 100vw !important;
    min-width: 80vw !important;
  }
}

@media (max-width: 1360px) {
  .terminalDetailsModalPopup {
    font-size: 13px;

    .difference_table_body {
      .differential_column {
        font-size: 16px !important;
      }
    }
  }
}
