@import 'theme.scss';
.country-selector {
  .react-custom-flag-select__select__options-container {
    width: 100%;
  }

  .react-custom-flag-select__select__container {
    width: 100%;

    ::-webkit-scrollbar {
      width: 6px !important;
    }
  }

  .react-custom-flag-select__select__buttonWrapper {
    height: 100%;
    border-radius: 4px;
    border: 1px solid $primary-color !important;
  }

  .react-custom-flag-select__select__searchInput {
    border: 1px solid $primary-color;
    outline-color: $primary-color;
    color: $Black;
  }

  .react-custom-flag-select__select__selector {
    padding: 0 7px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: start;
    width: 190px;
  }

  .react-custom-flag-select__select__hover-active {
    background-color: $primary-color;
    color: $White;
  }

  .react-custom-flag-select__select__options-item div {
    text-align: center;
  }

  .react-custom-flag-select__select__options-item div {
    text-align: left !important;
  }

  .react-custom-flag-select__select__dropdown-flag {
    width: 32px;
    flex: none !important;
  }

  .react-custom-flag-select__select__dropdown-flag img {
    text-align: left;
    width: 30px !important;
    border-radius: 2px;
    border: 1px solid rgba(0, 17, 51, 0.15);
  }

  .react-custom-flag-select__select__dropdown-flag div {
    text-align: left !important;
  }

  .react-custom-flag-select__select__dropdown-name {
    justify-content: start !important;
    text-align: left;
  }

  .react-custom-flag-select__select__dropdown-name div {
    text-align: left;
    width: 100%;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
  }

  .react-custom-flag-select__select__options-item-show-cursor {
    display: grid !important;
    grid-template-columns: 32px 1fr;
    column-gap: 11px;
  }
  .arrow-icon {
    rotate: 0deg;
    color: gray;
  }
  .react-custom-flag-select__show {
    .arrow-icon {
      rotate: 180deg;
    }
  }
}
