@import 'theme.scss';

.labelInHeader {
  margin-bottom: 0px !important;
  margin-top: 15px !important;
  font-size: 14px;
  color: $primary-color;
  color: gray;
  .mandate {
    color: $red;
    margin: 0px 3px;
  }
}

.pre-header {
  background-color: $primary-color;
  position: fixed;
  z-index: 102;
  width: 100%;
  top: 0px;

  .newNavBar {
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    padding: 0px 25px;

    .forLargeScreen {
      display: block;

      .logout-icon {
        color: $default-white;
        width: 32px;
        height: 32px;
        cursor: pointer;
      }

      .coffeeWebLogo {
        height: 47px;
        cursor: pointer;
      }
    }

    .forMobileScreen {
      display: none;
    }

    .avatar-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      height: 28px;
      width: 28px;
      border: 2px solid white;
      border-radius: 50%;
      cursor: pointer;
    }

    .subActionsContainer {
      .subActionsDiv {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 15px;

        .forLargeScreen {
          .avatarContainer {
            height: 50px;
            margin-top: 23px;
          }
        }
      }

      .auth-screen-button {
        border: 1px solid #fff;
        font-size: 14px;
        padding: 6px 12px;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        background: linear-gradient(-45deg, #e73c7e, #23a6d5);
      }
    }
  }
}

.logoutPopup {
  padding: 0px !important;
}

.signup_form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $primary-color !important;
  border-radius: 0.25rem !important;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:focus {
    border: 1px solid $primary-color !important;
    color: unset !important;
    background-color: #fff;
    border-color: #6a94ed !important;
    outline: 0;
    box-shadow: unset !important;
  }
}

select {
  border: 1px solid #d4d9df !important;
}

.pass-wrapper {
  position: relative;
  display: flex;
  width: auto;

  .pass_flow {
    border-radius: unset;
  }

  .eye_icon {
    position: absolute;
    top: 16%;
    border: unset;
    background: unset;
    width: auto;
    right: 9px;

    img {
      width: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: 767.98px) {
  .container-fluid {
    display: flex;
    justify-content: center !important;
  }

  .pre-header {
    .newNavBar {
      padding: 0px 15px;

      .forLargeScreen {
        display: none;
      }

      .forMobileScreen {
        display: block;

        .mobileScreenMenuIcon {
          color: $default-white;
          width: 38px !important;
          height: 38px;
        }

        .coffeeWebLogo {
          height: 47px;
        }
      }

      .mobile-screen-display-page {
        color: $default-white;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }

  .mobileScreenDrawer {
    .mobileScreenLogoWrapper {
      background-color: $primary-color;
      height: 50px;
      display: flex;
      justify-content: space-between;
      padding: 3px 15px;

      img {
        height: 46px;
      }

      .close-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $White;
      }
    }

    .sideBar {
      padding: 10px;
      font-size: 20px;
      gap: 10px;
      width: 285px;
      height: 100%;

      .sideBarCardWrapper {
        border-radius: 10px;
        margin-bottom: 32px;

        .sideBarCard {
          padding: 0.5rem;
          border-radius: 10px;
          background-size: 400% 400%;
          position: relative;
        }

        .sideBarCard > div:nth-child(1) {
          display: flex;
          gap: 10px;
        }

        .sideBarCard > div:nth-child(1) > div:nth-child(1) > img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
          margin-top: 4px;
          box-shadow: 0px 0px 5px gray;
        }

        .sideBarCard > div:nth-child(1) > div:nth-child(2) {
          color: white;
          width: 100%;

          > div:nth-child(1) {
            display: flex;
            justify-content: space-between;
            font-size: 18px;

            .userFirstLastName {
              cursor: pointer;
            }

            .editButtonDiv {
              .edit-icon {
                width: 18px;
                cursor: pointer;
              }
            }
          }

          > div:nth-child(2) {
            font-size: 13px;
          }
        }

        .sideBarCard > div:nth-child(2) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 0px;

          > div:nth-child(1) {
            font-size: 13px;
            color: $default-white;
            font-weight: 600;
            padding-left: 0.25rem;
            padding-bottom: 0.25rem;
          }

          > div:nth-child(2) {
            font-size: 16px;
            color: $default-white;
            padding-left: 0.25rem;
          }
        }

        .sideBarCard > div:nth-child(3) {
          display: flex;
          justify-content: center;
          font-size: 16px;

          .upgradeButton {
            background-color: $default-white;
            position: absolute;
            padding: 1px 15px;
            bottom: -13px;
            border-radius: 15px !important;
          }
          .renew-button {
            text-transform: uppercase;
          }
          .upgradeButton.guest-user-text-border {
            border: 1px solid #ae5e49;
            color: #ae5e49;
          }
        }

        .date-time-wrapper {
          width: 100%;
          font-size: 12px;
          color: #fff;

          .current-date {
            padding-bottom: 0 !important;
            font-weight: normal !important;
          }

          .current-time {
            font-size: 13px !important;
            padding-bottom: 0 !important;
            font-weight: normal !important;
          }
        }
      }

      .sidebar-logout-setting-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 5px;
        top: 86%;
        position: sticky;

        .sidebar-action {
          border-radius: 5px;
          margin-top: 10px;
          cursor: pointer;

          .sidebar-action-icon {
            width: 22px;
            margin: 0 4px;
          }
        }

        .sidebar-action-logout {
          border-radius: 5px;
          color: $White;
          cursor: pointer;
          display: flex;
          justify-content: center;
        }

        .logout-button {
          align-items: center;
          background-color: $primary-color;
          display: flex;
          font-size: 15px;
          justify-content: center;
          border-radius: 6px;
          padding: 6px 12px;
          gap: 10px;
        }

        .logout-button.guest-user-text-border {
          border: 1px solid #ae5e49;
          background-color: #ae5e49;
        }

        .logout {
          font-weight: $fw-bold;
        }
      }

      .buy-subscription-container {
        display: flex;
        justify-content: center;

        .buy-subscription {
          padding: 6px;
        }
      }
    }
  }

  .pre_header .navbar {
    padding: 1px 0px !important;
    z-index: 9;
  }

  .navbar-nav li {
    margin-left: 8px;
  }

  .navbar-collapse {
    margin-left: 10px;
  }

  .main_logo {
    width: 250% !important;
    height: 74px !important;
  }
}

.nav-link :hover {
  cursor: pointer !important;
}

.avatarCard {
  visibility: hidden;
  position: fixed;
  background-image: var(--custom-background, linear-gradient(100deg, $primary-color, $primary-color, $primary-color));

  .avatarDiv {
    display: flex !important;
    gap: 10px;

    .avatarUserCountryImg {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      object-fit: cover;
      margin-top: 4px;
      box-shadow: 0px 0px 5px gray;
    }

    .avatar-user-personal-details {
      margin-right: 7px;
      width: 100%;

      .username-edit-button {
        display: flex;
        justify-content: space-between;
        font-size: 18px;

        .edit-icon {
          width: 18px;
          cursor: pointer;
        }
      }

      .user-occupation {
        position: relative;
      }
    }
  }

  .userSubscriptionDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 0px;

    .subscriptionDate {
      font-size: 13px;
      color: white;
      font-weight: 600;
    }

    .subscriptionType {
      font-size: 16px;
    }
  }

  .upgrade-button-large-screen-container {
    display: flex;
    justify-content: center;

    .upgradeButtonLargeScreen {
      background-color: $default-white;
      position: absolute;
      padding: 4px 15px;
      border-radius: 15px !important;
      bottom: -16px;
      border: 2px solid $gray;
      color: $Black;
      text-transform: uppercase;
    }
  }
}

.tooltipLogout {
  visibility: hidden;
  position: fixed;
}

.avatarContainer:hover .avatarCard {
  font-size: 14px;
  padding: 10px;
  border: 2px solid gray;
  cursor: pointer !important;
  visibility: visible;
  border-radius: 12px;
  right: 80px;
  z-index: 1400 !important;

  background-size: 400% 400%;
  animation: gradient 7s ease infinite;

  margin-top: 3px;
  color: white;
  min-width: 420px;
}

.hoverOnLogout:hover .tooltipLogout {
  visibility: visible;
  margin-left: -15px;
  background-color: white;
  padding: 4px 10px;
  border-radius: 4px;
  color: rgb(0, 132, 255);
  box-shadow: 0px 0px 5px gray;
  cursor: pointer;
}

// logout modal
.logoutModal {
  top: 15% !important;
  width: '400px';
  margin-left: '400px';

  .logoutModalHeader {
    background-color: $primary-color;
    text-align: center;
    padding: 0px;

    .coffeeWebLogoModal {
      display: inline-block;
      align-items: flex-start;
      width: 180px;
      margin: auto;
      padding: 16px;
    }

    .coffeeWebDevLogo {
      display: inline-block;
      align-items: flex-start;
      width: 150px;
      margin: auto;
      padding: 0px;
    }
  }

  .logoutModalBody {
    .logoutText {
      text-align: center;
      padding: 30px;
    }

    .logoutModalButtons {
      margin: auto;
      display: flex;
      justify-content: center;
      margin-top: -17px;

      .logoutModalButtonYes {
        width: 70px;
        margin: 10px;
        height: 35px;
      }

      .logoutModalButtonNo {
        width: 70px;
        margin: 10px;
        background-color: red !important;
      }
    }
  }
}

.logoutPopupModal {
  .logoutModalHeader {
    background-color: $primary-color;
    text-align: center;
    padding: 0px;

    .coffeeWebLogoModal {
      display: inline-block;
      align-items: flex-start;
      width: 180px;
      margin: auto;
      padding: 16px;
    }

    .coffeeWebDevLogo {
      display: inline-block;
      align-items: flex-start;
      width: 150px;
      margin: auto;
      padding: 0px;
    }
  }

  .logoutPopupModalBody {
    .logoutPopupModalBodyWrapper {
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      .logoutPopupModalBodyImageDiv {
        display: block;

        img {
          width: 100px;
          margin: 10px;
        }
      }

      .countDownCircleContainer {
        display: flex;
        justify-content: center;
        margin: 15px 15px 20px 15px;

        .remaining-time-text {
          font-size: 24px;
          display: flex;
          margin: auto;
        }
      }

      .logoutPopupText {
        color: black;
      }
    }
  }

  .logoutPopupModalFooter {
    button {
      width: 80px;
      height: 30px;
    }
  }
}

//profile modal
.profile-modal {
  .payment-history-screen {
    .subHeading {
      width: 100%;
      margin-top: 5px;
    }
    .payment-history-header {
      position: sticky;
      top: 0;
    }
    .payment-history {
      .error-message {
        padding-top: 9px;
      }
      .table-responsive {
        max-height: 230px;
      }
      .bootstrap-data-table {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
      }
      .bootstrap-data-table table tr th:nth-child(1) {
        width: 25%;
      }
      .bootstrap-data-table table tr th:nth-child(2),
      .bootstrap-data-table table tr th:nth-child(3),
      .bootstrap-data-table table tr th:nth-child(4) {
        width: 20%;
      }
    }
  }

  .profile-modal-header {
    padding: 0.5rem 1rem;

    .profile-modal-close-button {
      cursor: pointer;
    }
  }

  .profile-modal-body {
    .profile-settings-tab-header {
      display: flex;
      width: 100%;
      margin: 10px 0px;
      cursor: pointer;
      border: 1px solid $primary-color;

      .each-tab-main-container {
        width: 50%;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .each-tabs {
          background-color: $White;
          border: 1px solid $primary-color;
          color: $primary-color;
          padding: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        #activeTabClass {
          background-color: $primary-color !important;
          color: $White !important;
        }
      }
    }

    .my-info-card {
      height: auto;
      background: none;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px;
      padding: 10px 0px 0px 0px;
      border-radius: 0.25rem;

      .container {
        .active-tab-text {
          margin: 10px 0px 0px 0px;
          color: $primary-color;
        }

        .profile_modal_input {
          color: #989898;
        }

        .profile_modal_select_dropdown {
          width: 100%;
        }
      }

      .profile_modal_footer_action {
        margin-top: 15px;
        width: 100%;
      }
    }

    .profileSettingsCard {
      height: auto;
      background: none;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px;
      padding: 10px 0px 0px 0px;
      border-radius: 0.25rem;

      .container {
        .active_tab_text {
          margin: 10px 0px 0px 0px;
          color: $primary-color;
        }

        .profile_modal_select_dropdown {
          width: 100%;
        }

        .admin_subscription_toggleBtn_container {
          cursor: pointer;
          padding: 5px;
          border: 1px solid rgb(80, 179, 246);
          border-radius: 4px;

          .admin_subscription_toggleBtn {
            width: 100%;
            height: 27px;
            display: flex;
            justify-content: center;
            border-radius: 4px;

            .admin_subscription_toggle_action {
              display: flex;
              background-color: #78c7fd;
              padding: 2px;
              width: 100%;
              border-radius: 4px;

              .toggle_action_active {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $default-white;
                font-size: 14px;
              }

              .toggle_action_inactive {
                width: 50%;
                background-color: $default-white;
                color: $default-white;
                border-radius: 4px;
              }
            }
          }
        }
      }

      .profile_modal_footer_action {
        margin-top: 15px;
        width: 100%;
      }
    }

    .changePasswordCard {
      height: auto;
      background: none;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px;
      padding: 10px 0px 0px 0px;
      border-radius: 0.25rem;

      .container {
        display: flex;
        justify-content: center;
        height: 100%;
        max-width: 400px;

        .active_tab_text {
          margin: 10px 0px 0px 0px;
          color: $primary-color;
        }

        .profile_modal_select_dropdown {
          width: 100%;
        }
      }

      .profile_modal_footer_action {
        margin-top: 15px;
        width: 100%;
      }
    }

    .accountActivityCard {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px;
      padding: 15px 8px;
      border-radius: 0.25rem;

      .mainHeading {
        color: $primary-color;
        margin-left: 5px;
        margin-bottom: 17px;
        margin-top: 5px;
      }

      .mainCard {
        .subCard {
          padding: 10px;
          margin-bottom: 4px;
          .subHeading {
            font-size: 18px;
            margin-top: 5px;
          }

          .text {
            color: gray;
            margin: 10px 0px;
          }

          .activityCardActionButtonDiv {
            display: flex;
            justify-content: center;

            .activityCardActionButton {
              background-color: white;
              border: 1px solid $primary-color;
              color: $primary-color;
              border-radius: 4px;
              padding: 4px 10px;
              margin: 10px;
            }
          }
        }
      }
    }

    .profile-save-button {
      background-color: $primary-color;
      color: $default-white;
      padding: 0.375rem 0.75rem;
      width: 95px;
    }

    .profile-close-button {
      background-color: $default-white;
      color: $primary-color;
      border: 1px solid $primary-color;
      padding: 0.375rem 0.75rem;
      width: 95px;
    }
  }
}

@media (min-width: 350px) {
  .navbar-expand-sm {
    flex-flow: row nowrap !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    justify-content: flex-start !important;
  }

  .navbar-nav {
    display: flex !important;
    flex-direction: row !important;
    padding: 5px !important;
    margin-left: 0px !important;
    list-style: none !important;
  }

  @media (min-width: 400px) and (max-height: 575px) {
    .popup {
      width: '200px';
    }

    .modal-dialog {
      max-width: 1260px !important;
    }

    .modal-lg,
    .modal-xl {
      max-width: 1000px !important;
      margin: auto;
      justify-content: center;
    }
  }
}

@keyframes lds-ring1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 767.98px) {
  .hoverOnLogout .tooltipLogout {
    display: none;
  }

  //profile modal mobile
  .profile-modal {
    .bootstrap-data-table table tr th:nth-child(1),
    .bootstrap-data-table table tr th:nth-child(2),
    .bootstrap-data-table table tr th:nth-child(3),
    .bootstrap-data-table table tr th:nth-child(4),
    .bootstrap-data-table table tr th:nth-child(5) {
      width: auto !important;
    }

    .profile-modal-body {
      .profile-settings-tab-header {
        display: flex;
        width: 100%;
        margin: 10px 0px;
        cursor: pointer;
        flex-direction: column !important;

        .each-tab-main-container {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}

.eachMenu {
  width: 100%;
  background-color: $primary-color;
  margin: 6px 0px;
  padding: 5px;
  border-radius: 5px;
  color: $default-white;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eachMenu:hover {
  width: 100%;
  background-color: $primary-color;
  margin: 6px 0px;
  padding: 5px;
  border-radius: 5px;
  color: $White;
}

.eachMenu:hover .side-menu-names {
  color: $White;
}

.side-menu-names {
  color: $White;
  text-decoration: none;
  width: 100%;
  display: flex;
  padding: 1px;
  align-items: center;
  font-size: 14px;
  text-decoration: none;

  .sideMenuIcons {
    width: 23px;
    height: 23px;
    margin-right: 5px;
    border-radius: 3px;
  }
}

.side-menu-names:hover {
  color: rgb(1, 48, 255);
  text-decoration: none;
  width: 100%;
}

button {
  background-color: $primary-color;
  border: none;
  border-radius: 5px !important;
  color: $White;
}

.sidebar-menus-wrapper {
  .sideMenu-container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
  .menu-icons-container {
    display: flex;
    flex-direction: column;
    width: 80px;
    height: fit-content;
    border-radius: 10px;
    gap: 5px;
  }
  .nav-link-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: $Black;
  }
  .menu-icons {
    margin: auto;
  }
  .menu-image {
    width: 45px;
  }

  .menu-name {
    font-size: 12px;
    text-align: center;
  }
}

.create-account-text {
  font-size: 12px;
  text-align: center;
  top: 80%;
  position: sticky;

  .create-account-label {
    color: #ae5e49;
  }
}

.sidebar-footer {
  position: sticky;
  top: 100%;
  background-color: #f2f1f1;
  font-size: 9px;
  padding: 5px;
  .sidebar-footer-terms {
    display: flex;
    gap: 3px;
  }
}

/* TODO : FOR THE FUTURE RELEASE */
// .sidebar-version-number {
//   display: flex;
//   justify-content: flex-end;
//   margin-right: 2px;
//   font-size: 8px;
//   color: $gray;
// }
