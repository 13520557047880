@import 'theme.scss';

.clock-settings-dialog {
  width: 55rem;

  .clock-type-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .clock-type-title {
      font-size: 16px;
      font-weight: $fw-bolder;
      color: #374151;
    }
    .select-button-wrapper {
      .p-button:focus {
        box-shadow: none;
        padding: 0.375rem 0.75rem;
      }
      .p-selectbutton .p-button.p-highlight {
        background: $primary-color;
        border-color: $primary-color;
        padding: 0.375rem 0.75rem;
        color: $White;
      }
      .p-selectbutton .p-button {
        padding: 0.375rem 0.75rem;
        border-color: $primary-color;
        color: $primary-color;
      }
    }

    .time-format-toggle {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .time-format-label {
        font-size: 16px;
        font-weight: $fw-bolder;
        color: #374151;
      }
      .time-format-select-button {
        .p-button:focus {
          box-shadow: none;
          padding: 0.375rem 0.75rem;
        }
        .p-selectbutton .p-button.p-highlight {
          background: $primary-color;
          border-color: $primary-color;
          padding: 0.375rem 0.75rem;
          color: $White;
        }
        .p-selectbutton .p-button {
          padding: 0.375rem 0.75rem;
          border-color: $primary-color;
          color: $primary-color;
        }
      }
    }
  }
  .my-favorite-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 16px;
    .country-pick-list-title {
      font-size: 16px;
      font-weight: $fw-bolder;
      color: #374151;
    }
    .error-message {
      color: $red;
      font-size: 14px;
    }
    .country-pick-list-container {
      .country-picker-item {
        display: flex;
        gap: 12px;
        align-items: center;
        .country-picker-flag-container {
          .country-picker-flag {
            width: 32px;
          }
        }
      }
      .country-picking-button {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        &:hover {
          background-color: $primary-color;
          border: 1px solid $primary-color;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .p-picklist-buttons {
        gap: 12px;
      }
      .p-picklist .p-picklist-list {
        height: 16rem;
      }
      .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
        color: $primary-color;
        background-color: #c8e9fa;
      }
      .p-inputtext:enabled:hover {
        border-color: $primary-color;
      }
    }
  }
  .dialog-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    .submit-button {
      background-color: $primary-color;
      color: $White;
      padding: 0.375rem 0.75rem;
      width: 95px;
      &:focus {
        box-shadow: none;
      }
    }
    .close-button {
      background-color: $White;
      color: $primary-color;
      border: 1px solid $primary-color;
      padding: 0.375rem 0.75rem;
      width: 95px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .p-dialog-header-icon.p-dialog-header-close.p-link {
    background-color: $White;
    color: #6b7280;
  }

  .p-dialog .p-dialog-content {
    padding: 0 1.5rem 0rem 1.5rem;
  }
}

@media (max-width: 1280px) {
  .clock-settings-dialog {
    width: 95%;
    .my-favorite-wrapper {
      .country-pick-list-container {
        .p-picklist {
          flex-direction: column !important;
        }
        .p-picklist .p-picklist-list {
          height: 17rem;
        }
        .p-picklist-transfer-buttons {
          flex-direction: row;
          gap: 0px;
        }
        .p-picklist-buttons .p-button {
          margin-right: 0.5rem !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .clock-settings-dialog {
    width: 94%;
    .my-favorite-wrapper {
      .country-pick-list-container {
        .p-picklist {
          flex-direction: column !important;
        }
        .p-picklist .p-picklist-list {
          height: 17rem;
        }
        .p-picklist-transfer-buttons {
          flex-direction: row;
          gap: 0px;
        }
        .p-picklist-buttons .p-button {
          margin-right: 0.5rem !important;
        }
      }
    }
    .clock-type-wrapper {
      margin-top: 6px;
    }
  }
}

@media (max-width: 280px) {
  .clock-settings-dialog {
    .my-favorite-wrapper {
      .country-pick-list-container {
        .p-picklist {
          flex-direction: column !important;
        }
        .p-picklist .p-picklist-list {
          height: 17rem;
        }
        .p-picklist-transfer-buttons {
          flex-direction: row;
          gap: 0px;
        }
        .p-picklist-buttons .p-button {
          margin-right: 0.5rem !important;
        }
        .p-inputtext {
          font-size: 12px;
        }
      }
    }
  }
}
