@import 'theme.scss';

.about-us-container {
  margin-top: 115px;

  .coffee-web-about-drop-clock {
    border-bottom: 2px solid $primary-color;
    margin: auto;
    margin-top: 14px;
  }
  .coffee-web-about-container {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 25px 25px 25px;
    user-select: none;
    .coffee-web-about-mobile-view {
      display: none;
    }
    .coffee-web-about-content-wrapper {
      width: 60%;
      padding: 20px;
      font-size: 20px;
      .about-content-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-color;
        font-weight: $fw-bolder;
        font-size: 24px;
      }
    }
    .coffee-web-about-drop {
      background-color: $primary-color;
      width: 2px;
    }
    .coffee-web-about-map-wrapper {
      width: 40%;
      display: flex;
      flex-direction: column;
      padding: 20px 1px 20px 20px;
      .coffee-web-about-map-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        padding-bottom: 0;
        span {
          background-color: $primary-color;
          padding: 10px;
          color: $White;
          border-radius: 5px;
        }
      }
      .world-map-image {
        margin-top: 2rem;
        img {
          width: 100%;
        }
      }
      .coffee-web-app-links {
        display: flex;
        justify-content: center;
        bottom: 10vh;
        left: 63%;
        right: 2%;
        gap: 28px;
        background-color: $White;
        .apple-link,
        .play-store-link {
          width: 100%;
          color: $White;
          margin: 32px 0px 0px 0px;
          text-align: left;
          border-radius: 5px;
          text-decoration: none;
          font-family: 'Caveat', cursive;
          font-size: 14px;
          text-transform: uppercase;
          background-color: $Black;
          border: 1px solid $light-gray-color;
          display: flex;
          padding: 1px;
          justify-content: center;
          width: 250px;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            p {
              margin: 0.5rem;
              color: $White;

              .big-txt {
                font-size: 18px;
                text-transform: capitalize;
              }
            }
            img {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }
  }
}

.text-align-last-center {
  text-align-last: center;
}

@media (max-width: 1024px) {
  .about-us-container {
    .coffee-web-about-container {
      display: block;
      padding: 0px;
      margin-bottom: 32px;
      p {
        margin-bottom: 4px;
      }
      .coffee-web-about-mobile-view {
        display: block;
        .coffee-web-about-map-heading {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 22px;
          font-size: 14px;
          padding-bottom: 0;
          span {
            background-color: $primary-color;
            padding: 5px 15px;
            color: $White;
            border-radius: 5px;
            z-index: 2;
          }
        }
        .world-map-image {
          margin-top: 2rem;
          img {
            width: 100%;
            padding: 8px;
            border-radius: 15px;
            padding-top: 0;
            margin-top: -13px;
          }
        }
      }
      .coffee-web-about-content-wrapper {
        width: 100%;
        padding: 0px 18px 18px;
        font-size: 18px;
        text-align: justify;
        margin-bottom: 12px;
        .about-content-heading {
          text-align: center;
          font-size: 20px;
        }
      }
      .coffee-web-about-drop {
        display: none;
      }
      .coffee-web-about-map-wrapper {
        width: 100%;
        padding: 10px;
        .coffee-web-about-map-heading {
          display: none;
          padding: 10px;
        }
        .world-map-image {
          margin-top: 2rem;
          display: none;
          img {
            width: 100%;
          }
        }
        .coffee-web-app-links {
          display: flex;
          justify-content: center;
          gap: 15px;
          left: 1%;
          right: 1%;
          padding: 0px 0px 5px 0px;
          bottom: 0px;
          z-index: 202;
          position: fixed;
          .apple-link,
          .play-store-link {
            font-size: 10px;
            width: 272px;
            margin: 0px;
            a {
              p {
                .big-txt {
                  font-size: 14px;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .about-us-container {
    margin-top: 90px;
    .coffee-web-about-container {
      display: block;
      padding: 0px;
      margin-bottom: 32px;
      p {
        margin-bottom: 4px;
      }
      .coffee-web-about-mobile-view {
        display: block;
        .coffee-web-about-map-heading {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 22px;
          font-size: 14px;
          padding-bottom: 0;
          span {
            background-color: $primary-color;
            padding: 5px 15px;
            color: $White;
            border-radius: 5px;
            z-index: 2;
          }
        }
        .world-map-image {
          margin-top: 2rem;
          img {
            width: 100%;
            padding: 8px;
            border-radius: 15px;
            padding-top: 0;
            margin-top: -13px;
          }
        }
      }
      .coffee-web-about-content-wrapper {
        width: 100%;
        padding: 0px 18px 18px;
        font-size: 18px;
        text-align: justify;
        margin-bottom: 12px;
        .about-content-heading {
          text-align: center;
          font-size: 20px;
        }
      }
      .coffee-web-about-drop {
        display: none;
      }
      .coffee-web-about-map-wrapper {
        width: 100%;
        padding: 10px;
        .coffee-web-about-map-heading {
          display: none;
          padding: 10px;
        }
        .world-map-image {
          margin-top: 2rem;
          display: none;
          img {
            width: 100%;
          }
        }
        .coffee-web-app-links {
          display: flex;
          justify-content: center;
          gap: 15px;
          left: 1%;
          right: 1%;
          padding: 0px 0px 5px 0px;
          bottom: 0px;
          z-index: 202;
          position: fixed;
          .apple-link,
          .play-store-link {
            font-size: 10px;
            width: 180px;
            margin: 0px;
            a {
              p {
                .big-txt {
                  font-size: 14px;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .about-us-container {
    .coffee-web-about-container {
      .coffee-web-about-map-wrapper {
        .coffee-web-app-links {
          .apple-link,
          .play-store-link {
            a {
              p {
                margin: 0rem;
              }
            }
          }
        }
      }
    }
  }
}
