@import 'theme.scss';

.global-differential-mobile-view {
  margin-bottom: 20px;
  .accordian-container {
    padding: 2px;
    display: none;

    .accordian_main_heading {
      background-color: $primary-color;
      color: $default-white;
      padding: 2px 10px;
      font-size: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .tr_heading {
        display: flex;
        justify-content: center;
      }
    }

    .accordian_sub_heading {
      .tr_sub_heading {
        font-size: 12px;
        $default-white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: start;
        display: grid;
        grid-template-columns: 1fr 1fr 20px;
        padding: 1px 3px;
        align-items: center;

        .first_row {
          display: flex;
          justify-content: start;
          font-size: 15px;
        }
        .differential-column {
          text-align: center;
          font-size: 15px;
          .range-starts {
            width: 24vw;
          }
          .range-ends {
            width: 12vw;
          }
        }

        .each_row_heading_name {
          display: flex;
          font-size: 15px;
          justify-content: center;
        }

        .history-icon {
          color: gray;
          width: 18px;
          margin-left: 5px;
        }
      }
    }

    .accordion-item {
      background-color: $default-white;
      display: flex;
      justify-content: start;
      flex-direction: column;

      button {
        background-color: $default-white;
        border: none;
        border-radius: 0px !important;
        color: $logo-text-color;
        font-size: 16px;
        width: 100%;
        justify-content: start;
      }
    }

    h2 {
      margin-bottom: 0px;
      color: black;
      border: 1px solid #d6d6d6;
      display: flex;
    }

    .accordian_main_body {
      padding: 4px;
      font-size: 15px;
      border: 1px solid #d6d6d6;
      border-top: 0;

      .accodian_sub_div {
        display: grid;
        grid-template-columns: 1fr;
        margin: 5px 0px;
      }

      .data_for_date {
        margin: 4px 2px;
        justify-content: center;
      }

      .each-data {
        display: flex;
        border-radius: 4px;
        margin: 2px;
        padding: 0px 8px;

        .row-heading {
          font-weight: 600;
        }
      }

      .each-data.historical-data {
        background-color: #40acf638;
        margin-top: 0;
        padding: 3px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        .popup-icon {
          height: 15px;
          width: 15px;
          margin-top: -2px;
          opacity: 0.8;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    .accordian-container {
      display: block;
    }
  }
}
