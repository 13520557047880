@import 'theme.scss';

.updated-time-pick {
  display: contents;

  .updated-time-pick-div {
    display: flex;
    gap: 4px;
    padding-left: 3px;

    .updated-time-pick-arrows {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.timeFormatModal {
  top: 15% !important;

  .timeFormatModalHeader {
    background-color: $primary-color;
    text-align: center;
    padding: 0px;

    .coffeeWebLogoModal {
      display: inline-block;
      align-items: flex-start;
      width: 180px;
      margin: auto;
      padding: 16px;
    }

    .coffeeWebDevLogo {
      display: inline-block;
      align-items: flex-start;
      width: 150px;
      margin: auto;
      padding: 0px;
    }
  }

  .timeFormatModalBody {
    .timeFormatText {
      text-align: center;
      padding: 21px;
      font-size: 18px;
    }

    .timeFormatModalButtons {
      margin: auto;
      display: flex;
      justify-content: center;
      margin-top: -11px;

      .timeFormatModalButtonYes {
        width: 70px;
        margin: 10px;
        height: 35px;
      }

      .timeFormatModalButtonNo {
        width: 70px;
        margin: 10px;
        background-color: $red;
      }
    }
  }
}
