@import 'theme.scss';

.coffeequtes-accordian_for_mobile {
  padding: 4px 7px;
  display: none;

  .accordian_main_heading {
    background-color: $primary-color;
    color: $default-white;
    padding: 2px 10px;
    font-size: 16px;
    display: grid;
    grid-template-columns: 26fr 10fr 11fr 11fr 3fr;

    .tr_heading {
      display: flex;
      justify-content: center;
    }
  }

  .accordian_sub_heading {
    .tr_sub_heading {
      font-size: 12px;
      $default-white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      justify-content: start;
      display: grid;
      grid-template-columns: 14fr 6fr 6fr 6fr 1fr;
      padding: 1px 3px;
      align-items: center;

      .first_row {
        display: flex;
        justify-content: start;
        font-size: 15px;
      }

      .each_row_heading_name {
        display: flex;
        font-size: 15px;
        justify-content: center;
      }
    }
  }

  .accordion-item {
    background-color: $default-white;
    display: flex;
    justify-content: start;
    flex-direction: column;

    button {
      background-color: $default-white;
      border: none;
      border-radius: 0px !important;
      color: $logo-text-color;
      font-size: 16px;
      width: 100%;
      justify-content: start;
    }
  }

  h2 {
    margin-bottom: 0px;
    color: black;
    border: 1px solid #d6d6d6;
    display: flex;
  }

  .accordian_main_body {
    padding: 1px 0px;
    font-size: 15px;

    .accodian_sub_div {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .bold_and_black {
      color: $logo-text-color;
      font-weight: 600;
    }

    .bold_and_red {
      color: $red;
      font-weight: 600;
    }

    .expired {
      color: #c5c5c5;
    }

    .data_for_date {
      margin: 4px 2px;
      justify-content: center;
    }

    .each_data {
      display: flex;
      margin: 4px 2px;
      background-color: #40acf638;
      border-radius: 4px;
      padding: 3px 8px;
    }

    .each_data div:nth-child(1) {
      margin-right: 5px;
    }
  }

  #change_color {
    button {
      background-color: #ccc;
    }
  }

  #idle_color {
    button {
      background-color: $default-white;
    }
  }

  #textNewGreen {
    color: $green;
    font-weight: 600;
  }

  #textNewRed {
    color: $red;
    font-weight: 600;
  }
}

@media (max-width: 767.98px) {
  .coffeequtes-accordian_for_mobile {
    display: block;
  }
}
