@import 'theme.scss';
.payment-status {
  .successpayment {
    margin-top: 130px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .cancel {
    margin-top: 130px;
    width: 100%;
    display: flex;
    justify-content: center;
    .cancel-conatiner {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
    .cancel-payment {
      color: $red;
      font-size: 25px;
    }
  }
}
.Thank-you {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 40px;
}
.Thankyou-second-div {
  color: #16d716;
  font-size: 35px;
}
.back-to-home-btn {
  background: $bg_MainColor;
  color: $default-white;
  text-align: center;
  border: 1px solid $bg_MainColor;
  padding: 8px 20px;
}
.Please-try-again {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 40px;
}
.Please-try-again-second-div {
  color: red;
  font-size: 30px;
}
.Back-to-home-btn {
  background: $bg_MainColor;
  color: $default-white;
  text-align: center;
  border: 1px solid $bg_MainColor;
  padding: 8px 20px;
}

.payment-status-modal {
  background-color: $primary-color;
  padding: 0px !important;

  .coffeeWebLogoModal {
    display: inline-block;
    align-items: flex-start;
    width: 180px;
    margin: auto;
    padding: 16px;
  }
  .coffeeWebDevLogo {
    display: inline-block;
    align-items: flex-start;
    width: 150px;
    margin: auto;
    padding: 0px;
  }
}
