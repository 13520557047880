@import 'theme.scss';

.modal-share-news-read-more {
  margin-top: 200px;
  .modal-share-news-title {
    margin: auto;
  }
  .modal-title-share-news-read-more {
    font-size: 20px;
  }
  .modal-close-btn-share-news-read-more {
    float: end;
    cursor: pointer;
    position: absolute;
    right: 15px;
  }
  .modal-body-share-news-read-more {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 15px 0px;
  }
}

.share-news-feed-container {
  background-color: white;
  height: auto;
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-direction: column;
  div {
    margin: 8px 8px;
  }
  .share-news-feed-div {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: 20px 0px;
    justify-content: center;

    .social-media-div {
      border: 2px solid rgb(217, 217, 217);
      border-radius: 10px;
      height: 82px;
      width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    .social-media-div:hover {
      border: 2px solid $primary-color;
    }
    .icons-text {
      margin-top: 4px;
      margin-bottom: 0px;
      color: $gray;
      font-size: 12px;
      text-align: center;

      cursor: pointer;
    }
    .copy-button {
      cursor: pointer;
      background-color: white;
      transition: background-color 0.3s ease;
    }

    .content-copy-icon {
      font-size: 20px;
      margin: 0px 2px;
      color: $gray;
      height: 32px;
      width: 32px;
    }
    .copy-text {
      margin-top: 4px;
      margin-bottom: 0px;
    }
  }
}
@media (max-width: 767.98px) {
  .share-news-feed-container {
    grid-template-columns: repeat(3, auto);
  }
}
