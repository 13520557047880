.pageNotFound {
  margin-top: 130px;
  width: 100%;
  display: flex;
  justify-content: center;

  .mainDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    img {
      width: 500px;
    }
  }
}

@media (max-width: 767.98px) {
  .pageNotFound {
    .mainDiv {
      img {
        width: 380px;
      }
    }
  }
}
