@import 'theme.scss';

.user {
  .supportRequest {
    width: 100%;
    height: auto;
    padding-right: clamp(2rem, 7vw, 15rem);
    padding-left: clamp(2rem, 7vw, 15rem);
    padding-top: 40px;
    padding-bottom: 50px;
    margin-top: 90px;

    .mainDiv {
      border-radius: 10px;
      box-shadow: 0px 0px 5px #27a1f3;
      box-shadow: 0px 0px 5px gray;
      padding: auto;
      padding: clamp(1rem, 4.5vw, 7rem);
      padding-top: 40px;
      justify-content: space-evenly;

      .support_image_container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .support_image_sub_container {
          display: flex;
          margin: 20px;

          .forLogo {
            width: 80%;
            margin: auto;
            padding: auto;
          }
        }

        .support_image {
          display: flex;
          justify-content: center;
          width: 83%;
        }
      }

      .support_logo_img {
        margin: auto;
        padding: auto;
      }

      .formDiv {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .support_form_h6 {
        color: $green;
      }

      .support_textfield_label {
        color: $Black;
      }

      .support_terms_and_condintions_container {
        margin: 8px 0px 0px 0px;
        font-size: 13px;
      }

      .support_checkbox {
        position: relative;
        top: 2px;
      }

      .support_checkbox_err_text {
        color: $red;
        font-size: 13px;
      }

      .submitBtn {
        background: $primary-color;
        color: $default-white;
        padding: 8px 12px;
        width: 120px;
        margin: auto;
      }

      span {
        font-size: 13px;
        color: $red;
        position: relative;
        margin: 1px 0px;
      }
    }

    .backBtnInSupportScreen {
      display: none;
      width: 115px;
      background-color: white;
      border-radius: 6px;
      gap: 4px;
      position: relative;
      padding: 3px;
      border: 1px solid #50b3f6;
      align-items: center;
      margin: auto;
      cursor: pointer;

      .back_button_div {
        background-color: #50b3f6;
        border-radius: 4px;

        .arrow_icon {
          color: white;
          width: 30px;
          height: 30px;
        }
      }

      .go_back_text {
        color: #50b3f6;
      }
    }
  }
}

//modal
.support_modal {
  margin-top: 100px;
}

.support_modal_body {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 50px;
}

.support_modal_h6 {
  color: $green;
  margin-bottom: 0px;
}

.support_modal_footer {
  width: 80px;
  height: 30px;
}

.support_circle_icon {
  width: 17px;
  height: 17px;
  margin: 1px 3px 0px 3px;
  color: $green;
}

@media (max-width: 767.98px) {
  .user {
    .supportRequest {
      margin-top: 0px;
      padding-right: 15px;
      padding-left: 15px;
      height: 94vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .mainDiv {
        border-radius: 10px;
        box-shadow: 0px 0px 0px #27a1f3;
        box-shadow: 0px 0px 0px gray;
        padding: auto;
        padding: 0px;
        padding-top: 0px;
        justify-content: space-evenly;

        .submitBtn {
          width: 100%;
        }
      }

      .support_image {
        width: 250px !important;
        margin-bottom: 20px !important;
      }

      .backBtnInSupportScreen {
        display: flex;
      }
    }
  }
}

.logoSvg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.forLogo {
  width: 90%;
  margin: auto;
  padding: auto;
}
