$default-font: 'Roboto, sans-serif';
$default-white: #fff;
$shade-of-white: #f9f9f9;
$background: #f2f6f8;
$primary-color: #50b3f6;
$on-primary-hover: #2078b3;
$secondary-color: #f1f1f1;
$button-disable-primary-color: #bdc3c7;

$logo-text-color: black;
$base-text-color: rgba(0, 0, 0, 0.8);
$border-color: rgba(0, 0, 0, 0.8);
$text-input-border-color: #ddd;

// font-weight
$fw-light: 200;
$fw-dark: 400;
$fw-bold: 500;
$fw-bolder: 700;

// color-code
$White: #fff;
$Black: #000;
$dark_gray: #3c3e3c;
$bg_MainColor: #50b3f6;
$bg_Secondary: #f1f1f1;
$red: red;
$gray: #6c757d;
$green: green;
$blue: blue;
$brown-primary-color: #ae5e49;

$black-shade: #343a40e6;
$light-gray-color: rgb(220, 220, 220);
$light-grey-border: #dee2e6;
$grey-font-color-on-hover: #343a40;
$danger-red-color: #ef4444;

$focus-shadow-color: 0 0 0 0.2rem #50b3f640;
$focus-shadow-danger: 0 0 0 0.2rem #f7a2a2;
$focus-shadow-danger:
  0 0 0 2px #fff,
  0 0 0 4px #f7a2a2,
  0 1px 2px 0 #000;

/* Font sizes in pixels */
$xxx-small: 8px;
$xx-small: 10px;
$x-small: 12px;
$small: 14px;
$medium: 16px;
$large: 18px;
$x-large: 20px;
$xx-large: 24px;
$xxx-large: 32px;

//Font-Weight
$font-thin: 100;
$font-extra-light: 200;
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

$button-label-padding: 0.5rem 0.75rem;

$device-medium: 992px;
$device-small: 768px;
