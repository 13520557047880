@import 'theme.scss';

.no-data-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  .no_data_found_image {
    width: 390px;
    margin: auto;

    .no_data_image {
      width: 100%;
      padding: 10px;
    }
    .error-share-news {
      width: fit-content;
      text-align: center;
      margin: auto;
      border: 1px solid $primary-color;
      background: $primary-color;
      color: $default-white;
      padding: 6px 16px;
      font-size: 18px;
      border-radius: 24px;
      margin-bottom: 36px;
    }
  }
}
.news_read_more_screen_main_div {
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  margin-top: 90px;
  z-index: 2;
  padding: 2px 10px;
  user-select: none;
  position: fixed;
  top: 0px;
  width: 100vw;
  background-color: $default-white;
  .news_read_more {
    .main-card {
      margin: auto;
      justify-content: space-around;
      display: grid;
      grid-template-columns: 2fr 7fr;
      gap: 10px;
      span,
      a {
        word-wrap: break-word !important;
      }
      .childMainDiv {
        padding: 10px;
      }

      .image_div {
        display: flex;
        flex-direction: column;
        gap: 4px;

        ::-webkit-scrollbar {
          width: 0px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #acabab;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #888;
        }

        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        .sticky_button {
          position: -webkit-sticky;
          position: sticky;
          top: 90px;
          background-color: white;
          padding: 4px 0px;
          display: flex;
          gap: 3px;
          z-index: 2;
          cursor: pointer;

          .back_button {
            display: flex;
            width: 50%;
            background-color: white;
            padding: 3px;
            border: 1px solid #50b3f6;
            border-radius: 6px;
            gap: 4px;
            padding: 4px;
          }
          .arrow_btn_container {
            background-color: $primary-color;
            border-radius: 4px;
          }
          .arrow_share_icons {
            color: $default-white;
          }
          .go_back_text {
            color: $primary-color;
          }
          .share_text {
            color: $default-white;
          }

          .share_news_button {
            display: flex;
            width: 50%;
            background-color: #50b3f6;
            padding: 3px;
            border: 1px solid #50b3f6;
            border-radius: 6px;
            gap: 4px;
            padding: 4px;
            cursor: pointer;
          }
          .share_icon_container {
            background-color: $primary-color;
            border-radius: 4px;
          }
          .share_icon {
            color: $default-white;
          }
        }

        .assets {
          width: 100%;
          border-radius: 10px;
          margin: 5px 0px;
        }
        .img_container {
          height: calc(100vh - 152px);
          padding: 10px;
          border-radius: 4px;
          box-shadow: 0px 0px 5px gray;
          overflow: scroll;
        }
      }

      .content_div::-webkit-scrollbar {
        width: 0px;
      }

      .content_div {
        overflow: scroll;
        height: 100vh;
        padding: 1px 5px;

        .sticky_button {
          position: -webkit-sticky;
          position: sticky;
          // top: 65px;
          background-color: white;
          padding: 4px 0px;
          gap: 3px;
          display: none;
          z-index: 2;

          .back_button {
            display: flex;
            width: 50%;
            background-color: white;
            padding: 3px;
            border: 1px solid #50b3f6;
            border-radius: 6px;
            gap: 4px;
            padding: 4px;
          }

          .share_news_button {
            display: flex;
            width: 50%;
            background-color: #50b3f6;
            padding: 3px;
            border: 1px solid #50b3f6;
            border-radius: 6px;
            gap: 4px;
            padding: 4px;
          }
        }

        .sticky_div {
          position: sticky;
          width: 100%;
          top: 0px;
          padding: 50px;
          font-size: 20px;
          z-index: 2;
          padding: 3px 10px;
          border-radius: 10px;
          box-shadow: 0px 0px 5px gray;
          background-color: $default-white;
          gap: 3px;
          display: flex;
          flex-direction: column;
          .sub_sticky_div {
            display: grid;
            grid-template-columns: auto 90px !important;
            justify-content: space-between;
            font-size: 14px;
            padding: 0px 3px;
            margin-top: 3px;
          }

          .sticky_icons_container {
            display: flex;
            align-items: center;
            font-size: 14px;
          }
          .calendar_clock_icons {
            color: $primary-color;
            width: 20px;
            margin-top: -3px;
            margin-right: 3px;
          }
          .sticky_box_bottom_part {
            position: absolute;
            bottom: 40px;
            right: 20px;
          }
          .account_icon_container {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .account_icon {
            color: $primary-color;
            width: 20px;
            margin-top: -4px;
            margin-right: 3px;
          }
          .right_part_icon_container {
            display: flex;
            justify-content: space-between;
          }
          .eyefilled_container {
            display: flex;
            align-items: center;
          }
          .eyefilled_icon {
            margin-right: 3px;
          }
          .heart_icon {
            color: $red;
            gap: 5px;
            display: flex;
            cursor: pointer;
          }
        }
        .stickyDiv2 {
          position: sticky;
          visibility: hidden;

          top: -2px;
          padding: 50px;
          font-size: 20px;
          z-index: 2;
          margin-top: 5px;
          .sub_sticky_div {
            display: grid;
            grid-template-columns: auto 90px !important;
          }
        }

        .banner_image {
          background-size: cover;
          width: 100%;
          height: auto;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          text-align: start;
          font-size: 25px;
          color: #50b3f6;
          font-weight: 600;
        }

        .carousel_for_news {
          aspect-ratio: 3 / 2;
          position: relative;
          z-index: 1;
          padding: 10px 0px;
          display: none;

          .carousel_container {
            width: 100%;
            height: 100%;
            padding: 0px 0px 20px 0px;
          }

          .carousel_img {
            width: 100%;
            height: 99%;
            object-fit: cover;
            margin-top: 50px;
          }
          span {
            width: 10px !important;
            height: 10px !important;
          }
        }
        .view_document_and_file_container {
          display: flex;
          justify-content: flex-end !important;
          margin-top: 150px;
        }
        .view_doc_btn {
          padding: 4px 10px;
        }
        .news_html {
          margin-top: 10px;
          margin-bottom: 150px;
        }
        .news_html_fullstop {
          color: $default-white;
          height: 75px;
        }
      }
    }
  }
}

.heartLogoBlob {
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.83);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.83);
  }
}

@media (max-width: 767.98px) {
  .news_read_more_screen_main_div {
    margin-top: 131px;
    z-index: 201;
    padding: 0px 5px;

    .news_read_more {
      .main-card {
        margin: auto;
        justify-content: space-around;
        display: grid;
        grid-template-columns: 7fr;
        height: 100%;
        gap: 10px;
        .childMainDiv {
          padding: 0px 10px 20px 10px;
        }

        .image_div {
          display: none;

          .sticky_div {
            top: 54px !important;
          }
        }

        .content_div::-webkit-scrollbar {
          width: 0px;
        }

        .content_div {
          height: 100vh;
          overflow: scroll;
          margin-top: 36px;
          margin-bottom: 50px;
          img {
            width: 100%;
          }
          .scrollBackBtn.slide-down {
            animation: slide-down-animation-back 0.2s ease-out;
            animation-fill-mode: forwards;
          }
          @keyframes slide-down-animation-back {
            0% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(100%);
            }
          }
          .scrollBackBtn.down {
            animation: down-animation-back 0.2s ease-out;
            animation-fill-mode: forwards;
          }
          @keyframes down-animation-back {
            0% {
              transform: translateY(100%);
            }
            100% {
              transform: translateY(0);
            }
          }

          .sticky_button {
            position: -webkit-sticky;
            position: fixed;

            bottom: -4px;
            width: 95%;
            background-color: white;
            padding: 12px 0px;
            display: flex;
            gap: 3px;
            .back_button {
              color: $primary-color;
              padding: 9px 0px 9px 6px;
              border-radius: 5px;
              .arrow_btn_container {
                background-color: $primary-color;
                border-radius: 4px;
              }
              .arrow_share_icons {
                color: $default-white;
              }
            }
            .share_news_button {
              color: $default-white;
              padding: 9px 0px 9px 6px;
              border-radius: 5px;
            }
            .go_back_text {
              padding-left: 11px;
              font-weight: bold;
            }
            .go_back_share_text {
              padding-left: 11px;
              font-weight: bold;
            }
          }

          .sticky_div {
            margin: auto;
            margin-top: 60px;
            left: 0px;
            right: 0px;
            position: fixed;
            width: 95% !important;
            border-radius: 4px;
            padding: 3px 4px;
          }
          .stickyDiv2 {
            display: none !important;
          }

          .banner_image {
            font-size: 20px;
            align-items: center;
          }

          .carousel_for_news {
            display: block;
          }
        }
      }
    }
  }
}
