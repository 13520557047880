@import 'theme.scss';
.country-code-select {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .mandate {
    color: $red;
  }
  label {
    margin-bottom: 0px;
    font-size: 13px;
    color: gray;
  }
  .country-dropdown {
    width: 300px;
    .loader {
      border: 1px solid $primary-color;
      width: 100%;
      border-radius: 4px;
      width: 100%;
      padding: 7px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .phone-number {
    .phone-number-field {
      display: flex;
      position: relative;

      .signup_form-control {
        border-color: $primary-color !important;
      }
      span {
        min-width: 50px;
        border: 1px solid $primary-color;
        color: #495057;
        border-radius: 4px 0px 0px 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 0px;
        background-color: #e9ecef;
      }
      input {
        border-bottom-left-radius: 0px !important;
        border-top-left-radius: 0px !important;
      }
    }
  }

  .react-custom-flag-select__select__options-container {
    width: 100%;
  }

  .react-custom-flag-select__select__container {
    width: 100%;

    ::-webkit-scrollbar {
      width: 6px !important;
    }
  }

  .react-custom-flag-select__select__buttonWrapper {
    height: 100%;
    border-radius: 4px;
    border: 1px solid $primary-color !important;
  }

  .react-custom-flag-select__select__searchInput {
    border: 1px solid $primary-color;
    outline-color: $primary-color;
    color: #000;
  }

  .react-custom-flag-select__select__selector {
    padding: 0 7px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: start;
    width: 230px;
  }

  .react-custom-flag-select__select__hover-active {
    background-color: $primary-color;
    color: #fff;
  }

  .react-custom-flag-select__select__options-item div {
    text-align: center;
  }

  .react-custom-flag-select__select__options-item div {
    text-align: left !important;
  }

  .react-custom-flag-select__select__dropdown-flag {
    width: 32px;
    flex: none !important;
  }

  .react-custom-flag-select__select__dropdown-flag img {
    text-align: left;
    width: 30px !important;
    border-radius: 2px;
    border: 1px solid rgba(0, 17, 51, 0.15);
  }

  .react-custom-flag-select__select__dropdown-flag div {
    text-align: left !important;
  }

  .react-custom-flag-select__select__dropdown-name {
    justify-content: start !important;
    text-align: left;
  }

  .react-custom-flag-select__select__dropdown-name div {
    text-align: left;
    width: 100%;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .react-custom-flag-select__select__options-item-show-cursor {
    display: grid !important;
    grid-template-columns: 32px 1fr;
    column-gap: 11px;
  }

  .text-danger-country {
    color: #dc3545;
    font-size: 16px;
  }
}

.country-code-select.error {
  display: flex;
  flex-direction: column;
  gap: 0;
}
