@import 'theme.scss';

:root {
  --setFontWeight: bold;
}
.post_header {
  background-color: $bg_Secondary;
  color: #000;
  margin-bottom: 35px;
  .navbar {
    padding: 0px !important;
  }
  select {
    background-color: transparent !important;
    color: #545454 !important;
    font-weight: 500 !important;
  }
  .whitelock {
    margin-right: -6px;
  }
  .whitelock:hover {
    margin-right: -6px;
    color: black;
  }
  .hamburger_btn {
    border: 1px solid #50b3f6;
    margin-top: -8px;
    padding: 0px 0px;
  }
  .span {
    position: relative;
    bottom: 10px !important;
  }
}
.post_header .nav-link {
  color: white !important;
  padding: 0.4rem 0.4rem !important;
  font-size: 12px;
}
.post_header .active_nav-link {
  color: #50b3f6 !important;
  background-color: white !important;
  border-radius: 4px !important;
  height: 31px;
}

#navbar-main {
  width: 100%;
  padding: unset !important;
  justify-content: center;
  display: grid;
  margin-top: -18px !important;
  height: 40px;
  top: 68px;
  position: fixed;
  z-index: 9;
  background-color: #f3f3f3;
  width: -webkit-fill-available;
}
.navbar-nav {
  align-items: center;
  height: 40px !important;
  .menucolor {
    background-color: $bg_MainColor;
    color: white !important;
    border-radius: 6px;
    height: 33px;
    border: 1px solid white;
  }
  .menucolor:hover {
    background-color: #2471a3;
    color: white !important;
    border-radius: 6px;
    height: 33px;
    border: 1px solid white;
  }
  .globalTradeBoxMenucolor {
    background-color: $bg_MainColor;
    color: black !important;
    border-radius: 6px;
    height: 33px;
    border: 1px solid white;
    margin-top: 0px;
  }
  .globalTradeBoxMenucolor .dd_container_2:hover .main_nav-link {
    color: white !important;
    background-color: #2471a3;
    height: 31px;
    border-radius: 5px;
  }
}
.coffee-link .nav-link {
  color: $bg_MainColor !important;
}
@media (max-width: 767.98px) {
  .container-fluid {
    display: flex;
    justify-content: center !important;
  }
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.multidropdown-menu {
  position: absolute;
  top: 100%;
  left: 100px !important;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: red;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 0.25rem;
  position: absolute;
  top: -57px;
  right: 0;
  z-index: 1030;
}

.login-warning-modal {
  background-color: $primary-color;
  padding: 0px !important;

  .coffeeWebLogoModal {
    display: inline-block;
    align-items: flex-start;
    width: 180px;
    margin: auto;
    padding: 16px;
  }
  .coffeeWebDevLogo {
    display: inline-block;
    align-items: flex-start;
    width: 150px;
    margin: auto;
    padding: 0px;
  }
}

@media (max-width: 767.98px) {
  .container-fluid {
    display: flex;
    justify-content: center !important;
  }
  .pre_header .navbar {
    padding: 1px 0px !important;
    height: 74px;
  }
  .navbar-nav {
    align-items: center;
    height: 74px !important;
  }

  #navbar-main {
    padding: unset !important;
    margin-bottom: 13px;
    justify-content: center;
    display: grid;
    z-index: 190;
    position: fixed;
    width: -webkit-fill-available;
    background-color: $bg_Secondary;
    margin-top: unset !important;
    height: 35px;
    display: none;
  }
}

.dd_container_2 {
  width: fit-content;
  height: auto;
  position: relative;
  .main_nav-link {
    color: white !important;
    padding: 0.4rem 0.4rem !important;
    font-size: 12px;
    margin-top: 0px;
    cursor: pointer;
  }
}
#modallarge {
  min-width: 1012px !important;
}
#navitem {
  margin-top: 0.1px !important;
}

.menulink {
  display: flex !important;
  justify-content: space-around !important;
}
.navlinks {
  list-style: none;
  .navlinks ul {
    display: flex !important;
    justify-content: space-around !important;
  }
}
.menucolor1 {
  height: 31px;
  background-color: #50b3f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  .nav-link1 {
    color: white;
    margin: auto;
    text-decoration: none;
    font-weight: var(--setFontWeight);
    height: 100%;
    display: flex;
    align-items: center;
  }
  .active_nav-link1 {
    height: 31px;
    color: #50b3f6;
    background-color: white;
    border: 1px solid #50b3f6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
  }
  .active_nav-link1:hover {
    height: 31px;
    color: white;
    background-color: #2471a3;
    border: 1px solid #50b3f6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    text-decoration: none;
  }
}
.menucolor1:hover {
  background-color: #2471a3;
}
.subsciption-alert-popup {
  .subsciption-alert-header {
    display: flex;
    justify-content: center;
    background-color: $primary-color;
    padding: 0px !important;

    .coffeeWebLogoModal {
      display: inline-block;
      align-items: flex-start;
      width: 200px;
      margin: auto;
      padding: 16px;
    }

    .coffeeWebDevLogo {
      display: inline-block;
      align-items: flex-start;
      width: 150px;
      margin: auto;
      padding: 0px;
    }
  }
  .subsciption-alert-body {
    margin: auto;
    display: flex;
    justify-self: center;
    flex-direction: column;
    text-align: center;
    padding: 30px 0px;
    .alert-title {
      font-size: 24px;
      margin-bottom: 15px;
    }
    .alert-message {
      color: rgba(0, 0, 0, 0.814);
      font-size: 18px;
    }
  }
  .subsciption-alert-footer {
    .soft-active-button {
      color: #50b3f6;
      border: 1px solid #50b3f6;
      background-color: white;
      height: 35px;
      width: 90px;
    }
    .hard-active-button {
      color: white;
      border: 1px solid #50bef6;
      height: 35px;
      width: 90px;
    }
  }
}
