@import 'theme.scss';

.popup_card_for_notification {
  max-height: 400px;
  overflow: scroll;
  overflow-x: hidden;
  width: 430px;

  .notifiction_sub_div {
    padding: 5px 15px;
    font-size: 20px;
    color: $primary-color;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: $default-white;
    z-index: 2;
    border-bottom: solid 2px lightgray;
  }
  .see_more_div {
    color: $primary-color;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }

  b {
    font-weight: 400;
  }

  #unRead {
    background-color: #e9ecef;
    color: rgb(46, 46, 46);
    border-left: 4px solid #50b3f6;
  }

  #unRead:hover {
    background-color: #e0e1e2;
    border-left: 6px solid #50b3f6;
  }
  .see-more-notification {
    color: $primary-color;
    background-color: $default-white;
    width: 100%;
    padding: 10px;
    text-align: center;
    border-top: #e0e1e2 2px solid;
    position: fixed;
    bottom: 0px;
    z-index: 2;
    border-radius: 0px 0px 10px 10px;
  }
  .see-all-notification-text {
    cursor: pointer;
    width: 50%;
    margin: auto;
    &:hover {
      text-decoration: underline;
    }
  }
}
.notification-modal {
  top: 15% !important;
  width: '400px';
  margin-left: '400px';

  .notification-modal-header {
    background-color: $primary-color;
    text-align: center;
    padding: 0px;

    .coffeeWebLogoModal {
      display: inline-block;
      align-items: flex-start;
      width: 180px;
      margin: auto;
      padding: 16px;
    }

    .coffeeWebDevLogo {
      display: inline-block;
      align-items: flex-start;
      width: 150px;
      margin: auto;
      padding: 0px;
    }
  }

  .notification-modal-body {
    .notification-text {
      text-align: center;
      padding: 30px;
    }

    .notification-modal-buttons {
      margin: auto;
      display: flex;
      justify-content: center;
      gap: 12px;
      margin-top: -17px;

      .notification-modal-yes-button {
        background-color: $primary-color;
        color: $default-white;
        padding: 0.375rem 0.75rem;
        width: 95px;
      }

      .notification-modal-no-button {
        background-color: $default-white;
        color: $primary-color;
        border: 1px solid $primary-color;
        padding: 0.375rem 0.75rem;
        width: 95px;
      }
    }
  }
}
.notification_icon_box {
  display: flex;
  align-items: center;
  text-align: center;

  .notification_icon {
    width: 33px;
    height: 33px;
    color: $default-white;
  }
}

.infinite_scroll {
  margin-top: 33px;
  margin-bottom: 40px;

  .infinite_scroll_div {
    display: flex;
    justify-content: center;
    color: $primary-color;
    position: relative;
  }
  .notifiction_menu {
    border-top: 1px solid lightgray;
  }
  .notifiction_list {
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .notifiction_html_news_list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .notification_news_date {
    color: $gray;
    font-size: 12px;
  }
}

.no-notification {
  padding: 5px 10px;
  margin: 20px 20px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: $gray;
}

@media (max-width: 767.98px) {
  .popup_card_for_notification {
    width: 100% !important;
  }
  .no-notification {
    padding: 30px 100px;
  }
}

.notification_badge span {
  padding: 1px 5px !important;
  height: 15px !important;
  font-size: 0.65rem !important;
  top: 3px !important;
  right: 3px !important;
}

.toast_div {
  position: fixed !important;
  bottom: 35px;
  right: 10px;
  .toast_sub_div {
    background-color: white;
    display: flex !important;
    flex-direction: row !important;
    padding: 15px;
    align-items: self-end;
    gap: 15px;
    box-shadow: 0px 0px 5px gray;
    border-radius: 8px;

    .toast_container {
      max-width: 200px;

      .toast_button {
        padding: 5px 10px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .toast_div {
    position: fixed !important;
    top: 25px;
    right: 0px;
    width: 100vw;
    padding: 0px 10px;

    .toast_sub_div {
      background-color: white;
      display: flex !important;
      flex-direction: row !important;
      padding: 15px;
      bottom: 35px;
      right: 10px;
      align-items: self-end;
      gap: 15px;
      box-shadow: 0px 0px 5px gray;
      border-radius: 8px;
    }
  }
}
