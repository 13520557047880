@import 'theme.scss';

.logo {
  width: '200px';
}

.modalBody {
  img {
    width: '100px';
    margin: '10px';
  }
  h5 {
    color: 'black';
    font-weight: '';
  }
}

button {
  width: '80px';
  height: '30px';
}
.login-warning-modal {
  background-color: $primary-color;
  padding: 0px !important;

  .coffeeWebLogoModal {
    display: inline-block;
    align-items: flex-start;
    width: 180px;
    margin: auto;
    padding: 16px;
  }
  .coffeeWebDevLogo {
    display: inline-block;
    align-items: flex-start;
    width: 150px;
    margin: auto;
    padding: 0px;
  }
}
