// @import "../../Styles/Colors.scss";
.loaderHOC {
  width: 100%;
  .loader-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffffcc;
    z-index: 9999;

    .loader {
      width: 80px;
      height: 80px;
      animation: heartbeat 1s infinite;
    }

    @keyframes heartbeat {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
