@import 'theme.scss';

textarea {
  resize: none !important;
  outline: unset !important;
}

.global_differentials_screens {
  margin-top: 90px;

  .global_differentials_headers {
    position: fixed;
    background-color: $White;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 15px 10px 15px;
    align-items: center;
    z-index: 2;
    .title {
      font-size: 25px;
      font-weight: 600;
    }

    .dropdown {
      display: grid;
      align-items: center;
      font-size: 18px;
      gap: 5px;
      grid-template-columns: auto 1fr;
      font-weight: 600;

      span {
        width: 100%;
      }
    }
  }

  .global_differentials_body {
    padding-top: 48px;
    padding-bottom: 25px;
    user-select: none;

    .last-table-divider {
      background-color: $primary-color;
      height: 3px;
      width: 98%;
      margin: auto;
      margin-bottom: 20px;
      border-radius: 10px;
    }

    .differential_table {
      padding: 0px 15px;

      .table_data {
        margin: 15px 0px;
        .year-mobile-view {
          display: none !important;
          background-color: $primary-color;
          color: white;
          font-weight: 600;
          border-radius: 4px;
          border-right: 1px solid #dee2e6;
          border-left: 1px solid #dee2e6;
          padding: 5px 14px;
          margin-bottom: 5px;
          align-items: center;
          display: flex;
          justify-content: space-between;

          .flag_and_cropyear {
            display: flex;
            gap: 20px;
            align-items: center;
            width: 45%;

            .crop_year_flag {
              font-size: 20px;
              display: flex;
              align-items: center;
              gap: 3px;

              img {
                width: 40px;
                border-radius: 3px;
                border: 1px solid rgba(0, 17, 51, 0.15);
                height: 28px;
                object-fit: cover;
                margin-right: 3px;
              }
            }
          }

          .year-date-wrapper {
            width: 55%;
            display: flex;
            justify-content: space-between;

            .updated_on {
              margin-left: -110px;
            }
          }
        }

        .year-web-view {
          background-color: $primary-color;
          color: white;
          font-weight: 600;
          border-radius: 4px;
          border-right: 1px solid #dee2e6;
          border-left: 1px solid #dee2e6;
          padding: 5px 14px;
          margin-bottom: 5px;
          align-items: center;
          display: flex;
          justify-content: space-between;

          .flag_and_cropyear {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .crop_year_flag {
              font-size: 20px;
              display: flex;
              align-items: center;
              gap: 3px;

              img {
                width: 40px;
                border-radius: 3px;
                border: 1px solid rgba(0, 17, 51, 0.15);
                height: 28px;
                object-fit: cover;
                margin-right: 3px;
              }

              .cropyear {
                font-size: 16px;
                margin-left: 50px;
              }
            }
          }
        }
        .table-divider {
          background-color: $primary-color;
          height: 3px;
          width: 100%;
          margin-bottom: 20px;
          border-radius: 10px;
        }
      }

      .each_table {
        width: 100%;
        margin-bottom: 20px;

        .differential_table_header {
          .blue-border {
            border: 2px solid $primary-color !important;
            background-color: rgb(233 241 249);
          }
          .quality-th-cell {
            width: 28vw;
          }

          .screen-th-cell {
            width: 8vw;
          }
          .serial-column {
            width: 3%;
          }
          .differetial-between {
            width: 18vw;
          }
          .td {
            width: 9vw;
          }

          .delivery_port {
            width: 30vw;
          }

          tr,
          th,
          td {
            border: 1px solid lightblue;
            text-align: center;
            color: $Black;
            padding: 4px 0px;
          }
          tr {
            background-color: $default-white;
            &:hover {
              background-color: rgba(0, 0, 0, 0.075) !important;
            }
          }
        }

        .differential_table_body {
          border: 1px solid $primary-color;

          tr,
          th,
          td {
            border: 1px solid lightblue;
            text-align: center;
            padding: 4px 0px;
          }
          tr:hover {
            background-color: rgba(0, 0, 0, 0.075);
            .fob-cell {
              background-color: $White;
            }
          }

          .range-starts {
            width: 10vw;
            font-weight: 600;
          }
          .range-ends {
            width: 10vw;
            font-weight: 600;
          }

          .quality-group-name-td {
            cursor: pointer;
            text-decoration: underline;
            width: 25vw;
            div {
              display: grid;
              grid-template-columns: 1fr;
              align-items: center;
              position: relative;
            }
            &:hover {
              color: blue;
              .popup-icon {
                opacity: 1;
              }
            }

            .popup-icon {
              height: 16px;
              width: 16px;
              opacity: 0.5;
              position: absolute;
              right: 28px;
            }
          }
        }
      }
    }

    .no_data_found {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 25px;
      padding: 45px;
      gap: 10px;
      margin-top: 40px;
      text-align: center;
    }
  }

  .guest-global-differential-dailog {
    width: 100%;
    padding: 15px;
    position: fixed;
    bottom: 0px;
    height: calc(100vh - 160px);
    margin: auto;
    display: flex;
    justify-content: center;
    user-select: none;
    align-items: center;
    z-index: 1;
    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;
      max-width: 450px;
      background-color: $White;
      padding: 15px 15px 25px 15px;
      border-radius: 8px;
      border: 1px solid lightgray;
      align-items: center;
      color: $primary-color;
      height: min-content;
      box-shadow: 0px 0px 2px gray;
      .coffeeweb-logo {
        margin-top: 20px;
        height: 50px;
      }
      .info {
        text-align: center;
        font-size: 18px;
      }
      .auth-screen-button {
        border: 1px solid $White;
        font-size: 16px;
        padding: 8px 15px;
        border-radius: 4px;
        color: $White;
        cursor: pointer;
        background: linear-gradient(-45deg, #e73c7e, #23a6d5);
        margin: auto;
        text-align: center;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .global_differentials_screens {
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    .global_differentials_headers {
      display: flex;
      justify-content: flex-end;
      padding: 12px 10px 10px 10px;

      .title {
        display: none;
      }

      .dropdown {
        font-size: 16px;
      }
    }

    .global_differentials_body {
      .differential_table {
        padding: 0px 10px;
        .table_data {
          .year-mobile-view {
            display: block !important;
            flex-direction: column;
            font-weight: 500;

            .flag_and_cropyear {
              font-size: 16px;
              width: 100%;
              display: flex;
              justify-content: space-between;
              padding: 2px 0;

              .crop_year_flag {
                font-size: 16px;
              }
            }

            .year-date-wrapper {
              width: 100%;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }

          .year-web-view {
            display: none !important;
          }

          .each_table {
            display: none;
          }
        }
      }
    }

    .differentials_entry_section {
      margin-bottom: 3rem;

      .form-control {
        width: inherit !important;
      }

      .differential_btns {
        text-align: start;

        .btn {
          width: 100px;
          margin: 0px 10px 20px 0px;
        }
      }
    }
  }
}
