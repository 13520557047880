@import 'theme.scss';

#coffeequotes_accordian_component {
  height: 22px;
  font-size: 16px;
  cursor: pointer;
  .bold_and_green {
    color: $green;
    font-weight: 600;
  }

  .bold_and_red {
    color: $red;
    font-weight: 600;
  }
  .expired {
    color: #c5c5c5;
  }
}
